<div class="border-red-500 border-l-[6px] rounded-md" role="alert">
  <div class="flex p-4">
    <div class="flex justify-center items-center">
      <div class="bg-red-500 rounded-full">
        <i-lucide name="x-circle" class="h-7 w-7 text-background"></i-lucide>
      </div>
    </div>
    <div class="ml-3">
      <h3
        class="font-bold text-foreground"
        *ngIf="title"
        [attr.aria-label]="title"
      >{{title}}</h3>
      <p
        *ngIf="message"
        role="alert"
        class="text-sm text-muted-foreground"
      >{{message}}</p>
    </div>
    <!-- Progress bar -->
    <div *ngIf="progressBar">
      <div class="toast-progress" [style.width]="width + '%'" [style.left]="'6px'"></div>
    </div>
  </div>
</div>
