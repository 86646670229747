import {Pipe, PipeTransform} from '@angular/core';
import {Package} from '@shared/models/package.model';
import {PackagingCalculationService} from '@shared/services/package/packaging-calculation.service';

@Pipe({
  name: 'grossWeight'
})
export class GrossWeightPipe implements PipeTransform {

  constructor(private calculationService: PackagingCalculationService) {}

  transform(value: Array<Package>): number {
    return this.calculationService.calculateGrossWeight(value);
  }

}
