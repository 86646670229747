import {Pipe, PipeTransform} from '@angular/core';
import {SessionUser} from '@shared/models/user.model';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  getFirstChar = (str: string) => str.charAt(0).toLocaleUpperCase();

  transform(value: SessionUser, ...args: unknown[]): string {
    return `${this.getFirstChar(value.firstName)}${this.getFirstChar(value.lastName)}`;
  }

}
