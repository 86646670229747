<div class="relative z-10 {{themeService.theme}}" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

      <div
          class="relative transform overflow-hidden bg-background rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 dark:bg-red-500 sm:mx-0 sm:h-10 sm:w-10">
              <div class="bg-red-500 rounded-full">
                <i-lucide name="alert-circle" class="h-7 w-7 text-background"></i-lucide>
              </div>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 class="text-base font-semibold leading-6 text-foreground" id="modal-title">
                {{header}}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-foreground">
                  {{body}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-accent px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          <p-button
              styleClass="p-button-sm p-button-danger w-full"
              class="text-sm ml-3"
              label="Confirm"
              (click)="buttonClicked.emit('Confirm')"
          ></p-button>
          <p-button
              styleClass="p-button-sm w-full"
              [text]="true"
              label="Cancel"
              (click)="buttonClicked.emit('Cancel')"
          ></p-button>
        </div>
      </div>
    </div>
  </div>
</div>


