<td pFrozenColumn>{{data.shipmentNumber}}</td>
<ng-container *ngFor="let col of headers">
  <ng-container [ngSwitch]="col.field">
    <td *ngSwitchCase="'status'">
      <app-shipment-status-tag [status]="data.status"></app-shipment-status-tag>
    </td>
    <td *ngSwitchCase="'collectionAddress'">{{data.collectionAddress | address}}</td>
    <td *ngSwitchCase="'collectionCountry'">{{data.collectionAddress.country}}</td>
    <td *ngSwitchCase="'deliveryAddress'">{{data.deliveryAddress | address}}</td>
    <td *ngSwitchCase="'deliveryCountry'">{{data.deliveryAddress.country}}</td>
    <td *ngSwitchCase="'documentOnly'">{{data.documentOnly}}</td>
    <td *ngSwitchCase="'expectedCollectionDate'">{{data.expectedCollectionDate | dateFormat}}</td>
    <td *ngSwitchCase="'expectedDeliveryDate'">{{data.expectedDeliveryDate | dateFormat}}</td>
    <td *ngSwitchCase="'createdDate'">{{data.createdDate | dateFormat}}</td>
    <td *ngSwitchCase="'createdBy'">{{data.createdBy.name}}</td>
    <td *ngSwitchCase="'financeOwner'">{{data.financeOwner.name}}</td>
    <ng-container *ngIf="col.field !== 'collectionCountry' && col.field !== 'deliveryCountry'">
      <td *ngSwitchDefault>{{data[col.field]}}</td>
    </ng-container>
  </ng-container>
</ng-container>
