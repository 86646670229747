import {Pipe, PipeTransform} from '@angular/core';
import {SessionUser} from '@shared/models/user.model';

@Pipe({
  name: 'fullname'
})
export class FullnamePipe implements PipeTransform {

  transform(value: SessionUser, ...args: unknown[]): unknown {
    return `${value.firstName} ${value.lastName}`;
  }

}
