import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {firstValueFrom, Observable, ReplaySubject, tap} from 'rxjs';
import {SessionUser, SessionUserList, User, UserForm} from '../../models/user.model';
import {Page} from '@shared/models/utility/page.model';
import {HttpParamBuilder} from '@core/utils/http-param-builder';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly user$: ReplaySubject<SessionUser> = new ReplaySubject();

  private url = '/api/user';

  constructor(private httpClient: HttpClient) {
    this.getCurrentUser().subscribe({
      next: (user: SessionUser) => this.user$.next(user),
      error: (error) => this.user$.error(error)
    });
  }

  public fetchAllUsers(): Observable<Array<User>> {
    return this.httpClient.get<Array<User>>(this.url);
  }

  public async hasPermissions(required: Set<string>): Promise<boolean> {
    const user = await firstValueFrom(this.user);
    const permissions = new Set(user.authorities);
    return [...required].every((value) => permissions.has(value));
  }

  get user(): Observable<SessionUser> {
    return this.user$?.asObservable();
  }

  public getCurrentUser(): Observable<SessionUser> {
    return this.httpClient.get<SessionUser>(`${this.url}/current-user`);
  }

  public getUser(id: string): Observable<SessionUser> {
    return this.httpClient.get<SessionUser>(`${this.url}/${id}`);
  }

  public fetchUserList(
    offset: number,
    size: number,
    search: string | null
  ): Observable<Page<SessionUserList>> {
    if(search === '') search = null;
    const params: HttpParams = HttpParamBuilder({offset, size, search});
    return this.httpClient.get<Page<SessionUserList>>(`${this.url}/list`, {params});
  }

  public updateUser(userId: string, form: UserForm): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/${userId}`, form);
  }

  public createUser(form: UserForm): Observable<SessionUser> {
    return this.httpClient.post<SessionUser>(`${this.url}`, form);
  }

}
