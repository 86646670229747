import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Address} from '@shared/models/address.model';
import {Observable, startWith} from 'rxjs';
import {Country} from '@shared/models/country.model';
import {CountryService} from '@shared/services/country/country.service';
import {LegalEntity} from '@shared/models/legal-entity.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-legal-entity',
  templateUrl: './legal-entity.component.html'
})
export class LegalEntityComponent implements OnInit, OnChanges, OnDestroy {

  @Input({required: true}) prefix!: string;
  @Input({required: true}) parentForm!: FormGroup;
  @Input({required: true}) parentFormGroupName!: string;
  @Input() address?: Address;
  @Input() readOnly = false;

  manualAddresses = false;
  legalEntityForm!: FormGroup;

  countryOptions$: Observable<Array<Country>>;

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService
  ) {
    this.countryOptions$ = this.countryService.countries.pipe(startWith([]));
    this.legalEntityForm = this.createFormGroup(this.address);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const addressChange = changes['address'];
    if(this.address && addressChange && addressChange.currentValue !== addressChange.previousValue) {
      this.legalEntityForm.setValue({
        erpId: null,
        name: addressChange.currentValue.name,
        address: {...addressChange.currentValue}
      });
    }
    this.manualAddresses = false;
  }

  ngOnInit(): void {
    this.listenToAddressChange();
    if(this.parentForm) {
      this.parentForm.addControl(this.parentFormGroupName, this.legalEntityForm);
      this.legalEntityForm.setParent(this.parentForm);
    }
  }

  createFormGroup(address?: Address): FormGroup {
    return this.fb.group({
      erpId: [null],
      name: [address?.name ?? null],
      address: this.fb.group({
        name: [address?.name ?? null],
        addressLine1: [address?.addressLine1 ?? null],
        addressLine2: [address?.addressLine2 ?? null],
        city: [address?.city ?? null],
        zone: [address?.zone ?? null],
        postalCode: [address?.postalCode ?? null],
        country: [address?.country ?? null]
      })
    });
  }

  ngOnDestroy(): void {
    if(this.parentForm) {
      this.parentForm.removeControl(this.parentFormGroupName);
    }
  }

  toggleManualAddresses(): void {
    this.manualAddresses = !this.manualAddresses;
  }

  private listenToAddressChange(): void {
    this.legalEntityForm.get('address')?.get('name')?.valueChanges
      .pipe(untilDestroyed(this)).subscribe((value) => {
        this.legalEntityForm.patchValue({name: value});
      });
  }

}
