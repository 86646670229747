<div class="border border-border rounded-lg w-full bg-background">
  <div class="flex flex-col h-full">
    <div class="border-border w-full border-b p-4 flex justify-between">
      <div>
        <a class="text-lg font-semibold leading-none tracking-tight hover:text-primary hover:underline"
          [routerLink]="'/dashboard/order/'+order.orderNumber">
          {{order.reference}}
        </a>
        <p class="text-muted-foreground">{{order.orderNumber}}</p>
      </div>
      <div class="border border-border rounded-md flex justify-center items-center px-2">
        <app-order-status-tag [styled]="false" [status]="order.status"></app-order-status-tag>
      </div>
    </div>
    <div class="flex-1">
      <div class="grid grid-cols-2 h-full">
        <div class="col-span-1 border-r border-border p-4">
          <h4 class="font-semibold">Collection</h4>
          <p>{{ order.collectionAddress | address }}</p>
          <p>{{ order.requestedCollectionDate | dateFormat }}</p>
        </div>
        <div class="col-span-1 p-4">
          <h4 class="font-semibold">Delivery</h4>
          <p>{{ order.deliveryAddress | address}}</p>
          <p>{{ order.requestedDeliveryDate | dateFormat }}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-center divide-x border-t border-border" *ngIf="order.packageStructure">
      <div class="flex justify-center items-center p-2 gap-x-2">
        <i-lucide name="snowflake" class="text-primary h-6 w-6" *ngIf="order.refrigerated" />
        <i-lucide name="blocks" class="text-primary h-6 w-6" *ngIf="order.stackable" />
        <i-lucide name="forklift" class="text-primary h-6 w-6" *ngIf="order.tailLiftRequired"/>
        <i-lucide name="alert-triangle" class="text-primary h-6 w-6" *ngIf="order.hazardous"/>
      </div>
      <p class="p-4">{{ order.packageStructure.packaging | countPallets}} Pallets</p>
      <p class="p-4">{{ order.packageStructure.packaging | countBoxes}} Boxes</p>
      <p class="p-4">{{ order.packageStructure.packaging | volume: order.measurementSystem | number}} {{volumeSuffix}}³</p>
      <p class="p-4">{{ order.packageStructure.packaging | grossWeight | number}} {{weightSuffix}}</p>
    </div>
    <div *ngIf="footer" class="border-t border-border">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  </div>
</div>
