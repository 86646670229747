import {Observable, OperatorFunction, Subject, combineLatest, defer, filter, finalize, map, timer} from 'rxjs';
import {DateTime} from 'luxon';

/*
 * RXJS utilities to be used as pipe operators
 *
 */

export function filterNil<T>() {
  return filter<T>((value: any) => !!value);
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => {
    return defer((): Observable<T> => {
      callback();
      return source;
    });
  };
}

export function indicateLoading<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next(true)),
    finalize(() => indicator.next(false))
  );
}

/*
 * Delays for a minimum period of time
 * Using combine latest, both must have completed once to emit
 * So we get a minimum delay of provided if observable has completed
 */

export function delayAtLeast<T>(delay: number): OperatorFunction<T, T> {
  return function(source$: Observable<T>): Observable<T> {
    return combineLatest([timer(delay), source$]).pipe(map((x) => x[1]));
  };
}

/*
 * Important - this only works for top level objects at the moment
 */

export function convertDatetimeToLuxon<T extends Record<keyof T, any>, Q extends Record<keyof T, any>>(): (
  source: Observable<T>
) => Observable<Q> {
  return (source: Observable<T>): Observable<Q> => source.pipe(map((object: T): Q => {
    const convertedValues: Q = {} as Q;
    const clonedObject: T = {...object};
    Object.keys(clonedObject).forEach((key: string): void => {
      const typedKeyT: keyof T = key as keyof T;
      const parsedDateTime = DateTime.fromISO(clonedObject[typedKeyT]);
      if(parsedDateTime.isValid) {
        convertedValues[typedKeyT] = parsedDateTime as Q[keyof T];
      } else {
        convertedValues[typedKeyT] = object[typedKeyT];
      }
    });
    return convertedValues;
  }));
}
