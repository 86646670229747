<div *ngIf="readOnly && contactInfoForm && (contactInfo?.name || contactInfo?.email || contactInfo?.phone)" class="p-4">
  <div class="flex items-center">
    <div class="p-2">
      <lucide-angular name="phone" class="text-primary"></lucide-angular>
    </div>
    <div>
      <p class="font-semibold text-muted-foreground dark:text-foreground">
        {{contactInfoForm.value | contactInfo }}
      </p>
    </div>
  </div>
</div>
<div class="pt-6" [formGroup]="contactInfoForm" *ngIf="!readOnly">
  <app-form-field>
    <label for="contactName">{{ prefix }} Contact Name</label>
    <div class="mt-2">
      <input
        id="contactName"
        appShowError
        pInputText
        [readOnly]="readOnly"
        class="w-full"
        formControlName="name"
        placeholder="Enter contact name"/>
    </div>
  </app-form-field>
  <div class="pt-6">
    <app-form-field>
      <label for="contactEmail">{{ prefix }} Contact Email</label>
      <div class="mt-2">
        <input
          id="contactEmail"
          appShowError
          pInputText
          [readOnly]="readOnly"
          class="w-full"
          formControlName="email"
          placeholder="Enter contact email"/>
      </div>
    </app-form-field>
  </div>
  <div class="pt-6">
    <app-form-field>
      <label for="contactPhone">{{ prefix }} Contact Phone</label>
      <div class="mt-2">
        <input
          id="contactPhone"
          appShowError
          pInputText
          [readOnly]="readOnly"
          class="w-full"
          formControlName="phone"
          placeholder="Enter contact phone"/>
      </div>
    </app-form-field>
  </div>
</div>
