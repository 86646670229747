import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {TableRowDirective} from '@shared/directives/table/table-row.directive';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';
import {TableLazyLoadEvent} from 'primeng/table/table.interface';
import {TableHeaderDirective} from '@shared/directives/table/table-header.directive';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html'
})
export class DatatableComponent<T extends object, Q extends object> {

  @Input({required: true}) tableColumns: Array<PrimeNgTableColumn<Q>> = [];
  @Input({required: true}) data: Array<T> = [];
  @Input({required: true}) rowCount = 10;

  @ContentChild(TableRowDirective<T, Q>, {read: TemplateRef}) rowTemplate!: TemplateRef<any>;
  @ContentChild(TableHeaderDirective<Q>, {read: TemplateRef}) headerTemplate!: TemplateRef<any>;

  @Input() pagination = true;
  @Input() totalRecords = 0;
  @Input() loading = false;
  @Input() selectionMode?: 'single' | 'multiple';

  @Output() actionClickedEvent: EventEmitter<T> = new EventEmitter<T>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChangeEvent: EventEmitter<TableLazyLoadEvent> = new EventEmitter<TableLazyLoadEvent>();

  public lazyLoadChange(data: TableLazyLoadEvent): void {
    if((!data.first || !data.rows) && data.first !== 0) throw new Error('Invalid Pagination Parameters');
    this.pageChangeEvent.emit(data);
  }

}
