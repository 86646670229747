<div *ngIf="warehouseAddressForm">
  <ng-container [formGroup]="warehouseAddressForm">
    <app-form-field>
      <label for="warehouse">{{label}}</label>
      <div class="mt-2">
        <p-dropdown
          id="warehouse"
          [autoDisplayFirst]="false"
          [options]="data"
          appShowError
          optionLabel="name"
          optionValue="id"
          [filter]="true"
          filterBy="name"
          [readonly]="readOnly"
          [showClear]="!readOnly"
          [autofocusFilter]="true"
          [showClear]="true"
          (onChange)="onWarehouseSelection($event.value)"
          formControlName="select"
          placeholder="Select an address..."
          [style]="{width: '100%'}"
        />
      </div>
    </app-form-field>
  </ng-container>

  <app-address-card
    *ngIf="address"
    [address]="address"
  ></app-address-card>

</div>
