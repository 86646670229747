import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {Observable, map} from 'rxjs';
import {BookingForm, CostingForm, Task, TaskDto} from '../../models/task.model';
import {Page} from '@shared/models/utility/page.model';
import {Filter} from '@shared/models/utility/filter';
import {HttpParamBuilder} from '@core/utils/http-param-builder';
import {convertArrayOfFiltersToObject} from '@core/utils/filters';
import {DropdownOption} from '@shared/models/utility/option.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentTaskService {

  private url = '/api/shipment';

  constructor(private _httpClient: HttpClient) {
  }

  public fetchActiveShipmentTask(id: string): Observable<Array<Task>> {
    return this._httpClient.get<Array<TaskDto>>(`${this.url}/${id}/task/active`).pipe(map((array) => array.map((dto) => ({
      ...dto,
      assignedDate: dto.assignedDate ? DateTime.fromISO(dto.assignedDate) : undefined,
      completedDate: dto.completedDate ? DateTime.fromISO(dto.completedDate) : undefined,
      createdDate: DateTime.fromISO(dto.createdDate)
    }))));
  }

  public fetchShipmentTasks(id: string): Observable<Array<Task>> {
    return this._httpClient.get<Array<TaskDto>>(`${this.url}/${id}/task`).pipe(map((array) => array.map((dto) => ({
      ...dto,
      assignedDate: dto.assignedDate ? DateTime.fromISO(dto.assignedDate) : undefined,
      completedDate: dto.completedDate ? DateTime.fromISO(dto.completedDate) : undefined,
      createdDate: DateTime.fromISO(dto.createdDate)
    }))));
  }

  public approveCostingTask(id: string, form: CostingForm): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${id}/cost`, form);
  }

  public approveBookingTask(id: string, form: BookingForm): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${id}/booking`, form);
  }

  public approveComplianceTask(id: string): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${id}/compliance`, {});
  }

  public approveCostingApprovalTask(id: string): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/task/${id}/cost-approval/approve`, {});
  }

  public getTaskTypes(): Observable<Array<DropdownOption<string>>> {
    return this._httpClient.get<Array<DropdownOption<string>>>(`${this.url}/task/types`);
  }

  public getTaskStatuses(): Observable<Array<DropdownOption<string>>> {
    return this._httpClient.get<Array<DropdownOption<string>>>(`${this.url}/task/statuses`);
  }

  public getListOrderByOptions(): Observable<Array<DropdownOption<string>>> {
    return this._httpClient.get<Array<DropdownOption<string>>>(`${this.url}/task/list/order-by-options`);
  }

  public fetchShipmentTaskList(
    offset: number,
    size: number,
    filters: Array<Filter>,
    search: string | null
  ): Observable<Page<Task>> {
    const params: HttpParams = HttpParamBuilder({offset, size, ...convertArrayOfFiltersToObject(filters), search});
    return this._httpClient.get<Page<TaskDto>>(`${this.url}/task/list`, {params}).pipe(map((dto) => ({
      ...dto,
      data: dto.data.map((task) => ({
        ...task,
        completedDate: task.completedDate ? DateTime.fromISO(task.completedDate) : undefined,
        assignedDate: task.assignedDate ? DateTime.fromISO(task.assignedDate) : undefined,
        createdDate: DateTime.fromISO(task.createdDate)
      }))
    })));
  }

  public assignCurrentUserToApproveCostTask(taskId: string): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${taskId}/cost-approval/assign`, {});
  }

  public assignCurrentUserToCostTask(taskId: string): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${taskId}/cost/assign`, {});
  }

  public assignCurrentUserToBookingTask(taskId: string): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${taskId}/booking/assign`, {});
  }

  public assignCurrentUserToComplianceTask(taskId: string): Observable<void> {
    return this._httpClient.post<void>(`${this.url}/task/${taskId}/compliance/assign`, {});
  }

}
