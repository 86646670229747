import {HttpParams} from '@angular/common/http';

// Convert object into HttpParams for use with httpClient
export function HttpParamBuilder(params: Record<string, any>): HttpParams {
  return new HttpParams({fromObject: RemoveMissingValuesFromObject(params)});
}

export function RemoveMissingValuesFromObject(object: Record<string, any>): Record<string, any> {
  return Object.keys(object)
    .filter((k) => object[k] !== null && object[k] !== undefined)
    .reduce((a, k) => ({...a, [k]: object[k]}), {});
}
