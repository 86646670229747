import {Component} from '@angular/core';
import {ThemeService} from '@core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  title = 'Freight';

  constructor(public themeService: ThemeService) {
  }

}

