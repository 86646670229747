<p-dialog [header]="!loading ? 'Select Documents to Upload' : 'Document uploading'"
          appendTo="body"
          [(visible)]="visible"
          [modal]="true"
          [draggable]="false"
          [style]="{width: '50%'}"
          (onHide)="onDialogHide()"
>
  <div *ngIf="loading" class="flex justify-center py-4">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div *ngIf="!loading">
    <div class="flex flex-row items-center justify-between align-middle">
      <span >Document Type</span>
      <div *ngIf="documentOptions$ | async as options">
      <p-dropdown appendTo="body"
                  [options]="options"
                  [(ngModel)]="selectedDocumentType"
                  [filter]="true"
                  optionLabel="name"
                  optionValue="type"
                  placeholder="Select Document Type"
      />
      </div>
    </div>
    <br>
    <div>
      <p-fileUpload [multiple]="true"
                    [files]="uploadedFiles"
                    [showUploadButton]="isUploadDisabled()"
                    [showCancelButton]="showCancelButton()"
                    [maxFileSize]="100000000"
                    (onClear)="onClear()"
                    (onSelect)="onFileSelect($event.files)"
                    (onBeforeUpload)="onBeforeUpload()"
                    accept=".pdf, .doc, .docx, .xls, .xlsx, .csv, .jpg, .png, .txt, .eml, .msg, .jpeg">
        <ng-template pTemplate="toolbar">
        </ng-template>
        <ng-template let-uploadedFile pTemplate="uploadedFiles">
        </ng-template>
        <ng-template pTemplate="content">
          <p class="text-center" *ngIf="uploadedFiles.length === 0">Drop the file here...</p>
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">
              <div class="flex flex-row items-center justify-between p-2">
                <div>{{file.name}}</div>
                <p-button styleClass="p-button p-button-danger"
                          [text]="true"
                          (click)="removeFile(file)"
                >
                  <lucide-icon name="x"></lucide-icon>
                </p-button>
              </div>
            </li>
          </ul>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>
</p-dialog>
