import {Filter, FilterValue} from '@shared/models/utility/filter';
import {FormGroup} from '@angular/forms';
import {DropdownOption} from '@shared/models/utility/option.model';

export function convertArrayOfFiltersToObject(filters: Array<Filter>): Record<string, FilterValue> {
  const filterObject: Record<string, FilterValue> = {};
  filters.forEach((filter: Filter): void => {
    filterObject[filter.key] = filter.value;
  });
  return filterObject;
}

export function createDisplayFilter(key: string, value: string, displayLabel: string, displayValue: string): Filter {
  return {
    key,
    value,
    displayLabel,
    displayValue
  };
}

export function createListDisplayFilter(key: string, value: Array<string>, displayLabel: string, displayValueArray: Array<string>): Filter {
  const displayValue = displayValueArray ? displayValueArray.join(', ') : '';

  return {
    key,
    value,
    displayLabel,
    displayValue
  };
}

// Helper function to simplify array lookups when form value doesn't match display.
export function createDisplayFilterWithArrayAndFormLookup(key: string, displayLabel: string, form: FormGroup, options: Array<DropdownOption<string>>): Filter {
  const value = form.get(key)?.value;
  const label = options.find((option) => option.value === value)?.label || '';
  return createDisplayFilter(key, value, displayLabel, label);
}

// Helper function to simplify array lookups when form value doesn't match display.
export function createMultiValueDisplayFilterWithArrayAndFormLookup(key: string, values: Array<string>, displayLabel: string, options: Array<DropdownOption<string>>): Filter {
  const displayValues = values ? values
    .map((value) => options.find((option) => option.value === value)?.label || '') : [];

  return createListDisplayFilter(key, values, displayLabel, displayValues);
}

export function upsertFilter(filter: Filter, existing: Array<Filter>): Array<Filter> {
  const reduced = existing.filter((f) => f.key !== filter.key);
  reduced.push(filter);
  return reduced;
}
