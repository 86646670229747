import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ThemeService} from '@core/services/theme.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {

  @Input() header = 'Please Confirm';
  @Input() body = '';

  @Output() buttonClicked: EventEmitter<'Confirm' | 'Cancel'> = new EventEmitter<'Confirm' | 'Cancel'>();

  constructor(public themeService: ThemeService) {}

}
