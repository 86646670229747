export enum WeightUom {
  G = 'G',
  KG = 'KG',
  LB = 'LB',
  ST = 'ST'
}

export interface Weight {
  value: number;
  unit: WeightUom;
}
