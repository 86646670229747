export enum MonoIntervalType {
  MONTH = 'MONTH',
  DAY = 'DAY'
}

export interface MonoInterval {
  type: MonoIntervalType
  duration: number
}

export interface Interval {
  days: number;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}

export enum DaysOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export const days = Object.keys(DaysOfWeek).map((key) => ({
  label: DaysOfWeek[key as keyof typeof DaysOfWeek],
  value: key
}));
