import {Component, Input} from '@angular/core';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';

@Component({
  selector: 'tr[app-generic-table-header]',
  templateUrl: './generic-table-header.component.html'
})
export class GenericTableHeaderComponent<T> {

  @Input({required: true}) headers!: Array<PrimeNgTableColumn<T>>;
  @Input() viewColumn = false;

}
