import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({
  name: 'dateFormat',
  pure: true
})
export class LuxonDatePipe implements PipeTransform {

  transform(value: DateTime | undefined, format = 'DATETIME_SHORT'): string {
    if(value === undefined) {
      return '';
    }
    return value.toLocaleString((<any>DateTime)[format]);
  }

}
