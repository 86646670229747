import {Pipe, PipeTransform} from '@angular/core';
import {ContactInformation} from '@shared/models/address.model';

@Pipe({
  name: 'contactInfo'
})
export class ContactInfoPipe implements PipeTransform {

  transform(value: ContactInformation): string {
    return [value.name, value.email, value.phone].filter((str) => str).join(' - ');
  }

}
