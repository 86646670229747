export interface Page<T> {
  data: Array<T>;
  limit: number;
  offset: number;
  total: number;
}

export function createEmptyPage<T>(): Page<T> {
  return {
    data: [],
    limit: 0,
    offset: 0,
    total: 0
  };
}
