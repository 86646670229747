import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpParamBuilder} from '@core/utils/http-param-builder';
import {Role, RoleForm, RoleList} from '@shared/models/role.model';
import {DropdownOption} from '@shared/models/utility/option.model';
import {Page} from '@shared/models/utility/page.model';
import {Observable, map} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private url = '/api/role';

  constructor(private httpClient: HttpClient) {}

  public fetchRoles(): Observable<Array<Role>> {
    return this.httpClient.get<Array<Role>>(this.url);
  }

  public fetchRoleOptions(): Observable<Array<DropdownOption<string>>> {
    return this.fetchRoles().pipe(
      map((roles: Array<Role>) => {
        return roles.map((role: Role) => ({
          label: role.name,
          value: role.id
        }));
      })
    );
  }

  public getRole(id: string): Observable<Role> {
    return this.httpClient.get<Role>(`${this.url}/${id}`);
  }

  public updateRole(roleId: string, form: RoleForm): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/${roleId}`, form);
  }

  public fetchRoleList(
    offset: number,
    size: number,
    search: string | null
  ): Observable<Page<RoleList>> {
    if(search === '') search = null;
    const params: HttpParams = HttpParamBuilder({offset, size, search});
    return this.httpClient.get<Page<RoleList>>(`${this.url}/list`, {params});
  }

}
