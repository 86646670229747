import {Pipe, PipeTransform} from '@angular/core';
import {Interval} from '@shared/models/interval.model';

type ShortenedCustomInterval = Omit<Interval, 'microSeconds' | 'seconds'>;

@Pipe({
  name: 'interval'
})
export class IntervalPipe implements PipeTransform {

  public highestToLowest: Array<keyof ShortenedCustomInterval> = ['years', 'months', 'days', 'hours', 'minutes'];
  public keyDisplayMap: Record<keyof ShortenedCustomInterval, Record<'short' | 'medium' | 'long', string>> = {
    years: {short: 'y', medium: 'y', long: ' Years'},
    months: {short: 'm', medium: 'm', long: ' Months'},
    days: {short: 'd', medium: 'd', long: ' Days'},
    hours: {short: 'h', medium: 'h', long: ' Hours'},
    minutes: {short: 'm', medium: 'm', long: ' Minutes'}
  };

  transform(interval: Interval | undefined, type: 'short' | 'medium' | 'long' = 'short'): string {
    if(!interval) return '';
    const highestIntervalKeys: Array<keyof ShortenedCustomInterval> = this.findLargestTwoIntervalKeys(interval);
    return highestIntervalKeys.map((key) => {
      return `${interval[key]}${this.keyDisplayMap[key][type]}`;
    }).join(' ');
  }

  private findLargestTwoIntervalKeys(interval: Interval): Array<keyof ShortenedCustomInterval> {
    const highestKeys: Array<keyof ShortenedCustomInterval> = [];
    this.highestToLowest.forEach((key) => {
      if(interval[key] > 0 && highestKeys.length < 2) highestKeys.push(key);
    });
    return highestKeys;
  }

}
