import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Organisation, User} from '@shared/models/user.model';
import {DropdownOption} from '@shared/models/utility/option.model';
import {map, Observable} from 'rxjs';
import {PermissionKey} from '@shared/models/permission-key.model';
import {HttpParamBuilder} from '@core/utils/http-param-builder';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private url = '/api/organisation';

  constructor(private httpClient: HttpClient) {}

  public fetchOrganisations(): Observable<Array<Organisation>> {
    return this.httpClient.get<Array<Organisation>>(this.url);
  }

  public fetchUsersWithPermissionsForOrganisations(organisationIds: string[], permissionKeys: PermissionKey[]): Observable<Array<User>> {
    const params: HttpParams = HttpParamBuilder({organisation: organisationIds, permission: permissionKeys});

    const urlBuilder = `${this.url}/user`;
    return this.httpClient.get<Array<User>>(urlBuilder, {params});
  }

  public fetchOrganisationOptions(): Observable<Array<DropdownOption<string>>> {
    return this.fetchOrganisations().pipe(
      map((organisations: Array<Organisation>) => {
        return organisations.map((org: Organisation) => ({
          label: org.name,
          value: org.id
        }));
      })
    );
  }

  public fetchUsersByOrganisationsAndPermissions(organisationIds: string[], permissionKeys: PermissionKey[]): Observable<Array<DropdownOption<string>>> {
    return this.fetchUsersWithPermissionsForOrganisations(organisationIds, permissionKeys).pipe(
      map((users: Array<User>) => {
        return users.map((user: User) => ({
          label: user.name,
          value: user.id
        }));
      })
    );
  }

}
