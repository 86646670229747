export enum PermissionKey {
  VIEW_ORDER = 'VIEW_ORDER',
  EDIT_ORDER = 'EDIT_ORDER',
  ORDER_ADVANCED_OPTIONS = 'ORDER_ADVANCED_OPTIONS',
  ORDER_ALLOCATION = 'ORDER_ALLOCATION',
  ORDER_DEALLOCATION = 'ORDER_DEALLOCATION',
  VIEW_SHIPMENT = 'VIEW_SHIPMENT',
  EDIT_SHIPMENT = 'EDIT_SHIPMENT',
  SHIPMENT_ADVANCED_OPTIONS = 'SHIPMENT_ADVANCED_OPTIONS',
  SUBMIT_COSTING = 'SUBMIT_COSTING',
  APPROVE_COSTING = 'APPROVE_COSTING',
  SUBMIT_BOOKING = 'SUBMIT_BOOKING',
  APPROVE_COMPLIANCE = 'APPROVE_COMPLIANCE',
  VIEW_USER = 'VIEW_USER',
  EDIT_USER = 'EDIT_USER',
  VIEW_ROLES = 'VIEW_ROLES',
  EDIT_ROLES = 'EDIT_ROLES'
}
