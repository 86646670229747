import {Component, NgZone} from '@angular/core';
import {ThemeService} from '@core/services/theme.service';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';

@Component({
  selector: '[app-toast]',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent extends Toast {

  constructor(
    public themeService: ThemeService,
    toastrService: ToastrService,
    toastPackage: ToastPackage,
    ngZone?: NgZone,
  ) {
    super(toastrService, toastPackage, ngZone);
  }

}
