import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, debounceTime, map, switchMap} from 'rxjs';
import {Address, SavedAddress, SavedAddressForm} from '../../models/address.model';
import {DropdownOption} from '../../models/utility/option.model';
import {Page} from '@shared/models/utility/page.model';
import {HttpParamBuilder} from '@core/utils/http-param-builder';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private url = '/api/address';

  constructor(private _httpClient: HttpClient) {
  }

  public fetchAddressList(
    offset: number,
    size: number,
    search: string | null
  ): Observable<Page<SavedAddress>> {
    if(search === '') search = null;
    const params: HttpParams = HttpParamBuilder({offset, size, search});
    return this._httpClient.get<Page<SavedAddress>>(`${this.url}/list`, {params});
  }

  public searchAddresses(search$: Observable<string>): Observable<Array<SavedAddress>> {
    return search$.pipe(
      debounceTime(500),
      switchMap((search) => this._httpClient.get<Array<SavedAddress>>(this.url, {params: {search: search}}))
    );
  }

  public getAddressLabel(address: Address): string {
    return Object.values(address).filter(Boolean)
      .join(', ');
  }

  public searchAddressesAsOptions(search$: Observable<string>): Observable<Array<DropdownOption<SavedAddress>>> {
    return this.searchAddresses(search$).pipe(map((array) => array.map((savedAddress) => this.addressToDropdownOption(savedAddress))));
  }

  public addressToDropdownOption(savedAddress: SavedAddress): DropdownOption<SavedAddress> {
    return {label: this.getAddressLabel(savedAddress.address), value: savedAddress};
  }

  public createAddress(form: SavedAddressForm): Observable<string> {
    return this._httpClient.post<string>(`${this.url}`, form);
  }

  public updateAddress(addressId: string, form: SavedAddressForm): Observable<void> {
    return this._httpClient.put<void>(`${this.url}/${addressId}`, form);
  }

  public getSavedAddress(addressId: string): Observable<SavedAddress> {
    return this._httpClient.get<SavedAddress>(`${this.url}/${addressId}`);
  }

}
