import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';

@Component({
  selector: 'tr[app-generic-table-row]',
  templateUrl: './generic-table-row.component.html',
  changeDetection: ChangeDetectionStrategy.Default
})
export class GenericTableRowComponent<T> {

  @Input({required: true}) data!: T;
  @Input({required: true}) headers!: PrimeNgTableColumn<T>[];

}
