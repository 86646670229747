import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'indexOf'
})
export class IndexOfPipe implements PipeTransform {

  transform<T>(items: Array<T>, item:T): number {
    return items.indexOf(item);
  }

}
