import {Component, EventEmitter, OnInit, Output, SkipSelf} from '@angular/core';
import {PrimeNgSidebarService} from '@shared/services/prime-ng-sidebar/prime-ng-sidebar.service';
import {Filter, FilterValue} from '@shared/models/utility/filter';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable, of, startWith, tap} from 'rxjs';
import {Shipment} from '@shared/models/shipment.model';
import {
  convertArrayOfFiltersToObject,
  createDisplayFilterWithArrayAndFormLookup,
  createMultiValueDisplayFilterWithArrayAndFormLookup
} from '@core/utils/filters';
import {FilterTableHeaderService} from '@shared/services/filter-table-header/filter-table-header.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ShipmentTaskService} from '@shared/services/shipment/shipment-task.service';
import {DropdownOption} from '@shared/models/utility/option.model';

type ShipmentTaskFiltersForm = { statuses: Array<string>; type: string; orderBy: string; };

@UntilDestroy()
@Component({
  selector: 'app-shipment-task-filters-sidebar',
  templateUrl: './shipment-task-filters-sidebar.component.html'
})
export class ShipmentTaskFiltersSidebarComponent implements OnInit {

  @Output() applyFiltersEvent: EventEmitter<Array<Filter>> = new EventEmitter<Array<Filter>>();

  public types: Array<DropdownOption<string>> = [];
  public statuses: Array<DropdownOption<string>> = [];
  public orderByOptions: Array<DropdownOption<string>> = [];

  public isOpen: Observable<boolean> = of(false);

  public form: FormGroup = this.fb.group<ShipmentTaskFiltersForm>({
    type: '',
    statuses: [''],
    orderBy: ''
  });

  constructor(
    private sidebarService: PrimeNgSidebarService,
    private fb: FormBuilder,
    private shipmentTaskService: ShipmentTaskService,
    @SkipSelf() private filterTableHeaderService: FilterTableHeaderService<Shipment>
  ) {
    this.shipmentTaskService.getTaskTypes().pipe(
      untilDestroyed(this),
      startWith([] as Array<DropdownOption<string>>),
    )
      .subscribe((data) => this.types = data);
    this.shipmentTaskService.getTaskStatuses().pipe(
      untilDestroyed(this),
      startWith([] as Array<DropdownOption<string>>),
    )
      .subscribe((data) => this.statuses = data);
    this.shipmentTaskService.getListOrderByOptions().pipe(
      untilDestroyed(this),
      startWith([] as Array<DropdownOption<string>>),
      tap((data) => {
        if(data.find((option) => option.value === 'CREATED_DATE')) {
          this.form.patchValue({orderBy: 'CREATED_DATE'});
        }
      })
    )
      .subscribe((data) => this.orderByOptions = data);
  }

  ngOnInit(): void {
    this.isOpen = this.sidebarService.isOpen;
    this.keepFormValuesUpToDate();
  }

  public close(): void {
    this.sidebarService.close();
  }

  public apply(): void {
    this.applyFiltersEvent.emit(this.buildArrayOfFiltersFromFormValues(this.form.value));
    this.close();
  }

  public cancel(): void {
    this.rollbackFormChanges();
    this.close();
  }

  public clearFilters(): void {
    this.form.reset();
    this.apply();
  }

  private rollbackFormChanges(): void {
    if(this.filterTableHeaderService.getFiltersAsValues().length > 0) {
      const valuesForFormReset: Record<string, FilterValue> = convertArrayOfFiltersToObject(this.filterTableHeaderService.getFiltersAsValues());
      this.form.patchValue({...valuesForFormReset});
    } else {
      this.clearFilters();
    }
  }

  private patchFormValuesFromServiceValues(serviceValues: Array<Filter>): void {
    const valuesForFormReset: Record<string, FilterValue> = convertArrayOfFiltersToObject(serviceValues);
    this.form.reset();
    this.form.patchValue(valuesForFormReset);
  }

  private buildArrayOfFiltersFromFormValues(formValues: ShipmentTaskFiltersForm): Array<Filter> {
    return [
      createMultiValueDisplayFilterWithArrayAndFormLookup('statuses', formValues.statuses, 'Statuses', this.statuses),
      createDisplayFilterWithArrayAndFormLookup('type', 'Type', this.form, this.types),
      createDisplayFilterWithArrayAndFormLookup('orderBy', 'Order By', this.form, this.orderByOptions)
    ];
  }

  private keepFormValuesUpToDate(): void {
    this.filterTableHeaderService.filters.pipe(
      untilDestroyed(this)
    ).subscribe((values) => {
      this.patchFormValuesFromServiceValues(values);
    });
  }

}
