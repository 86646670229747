import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {OrderStatus} from '@shared/models/order.model';
import {OrderService} from '@shared/services/order/order.service';
import {StatusStyle} from '@shared/models/status.model';

@Component({
  selector: 'app-order-status-tag',
  templateUrl: './order-status-tag.component.html'
})
export class OrderStatusTagComponent implements OnChanges {

  @Input({required: true}) status?: OrderStatus;
  @Input() styled = true;

  statusStyle!: StatusStyle;

  constructor(private orderService: OrderService) {}

  private setStatus(): void {
    if(!this.status) return;
    this.statusStyle = this.orderService.fetchOrderStatusStyling(this.status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['status'] && changes['status'].previousValue !== changes['status'].currentValue) this.setStatus();
  }

}
