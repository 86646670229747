import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'titlecaseWithSpace'
})
export class TitlecaseWithSpacePipe implements PipeTransform {

  transform(value: string): string {
    if(!value) return '';

    value = value.replace(/_/g, ' ');

    return value.toLowerCase().split(' ')
      .map((word) => {
        return (word.charAt(0)).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

}
