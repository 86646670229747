<div class="mx-auto py-10">
  <div class="flex items-center justify-between space-y-2">
    <div>
      <h1 class="text-3xl font-extrabold md:text-4xl">
        {{ title }}
      </h1>
      <p class="max-w-[700px] text-lg text-muted-foreground" *ngIf="description">
        {{ description }}
      </p>
    </div>
    <div class="flex items-center space-x-2">
      <p-button
        *ngIf="buttonText && showButton"
        [label]="buttonText"
        styleClass="p-button-sm"
        (click)="buttonClicked($event)"></p-button>
    </div>
  </div>
</div>
