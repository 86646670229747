import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';

export function initKeycloak(
  keycloak: KeycloakService
) {
  return () => keycloak.init(
    {
      ...environment.keycloak,
      initOptions: {
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri: `${window.location.origin}/assets/silent-check-sso.html`
      }
    });
}
