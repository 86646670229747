import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SkipSelf} from '@angular/core';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';
import {Filter} from '@shared/models/utility/filter';
import {FilterTableHeaderService} from '@shared/services/filter-table-header/filter-table-header.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-filter-table-header',
  templateUrl: './filter-table-header.component.html'
})
export class FilterTableHeaderComponent<T> implements OnInit, OnChanges {

  @Input() allowFiltering = true;
  @Input() dropdownOptions: Array<PrimeNgTableColumn<T>> = [];
  @Input() searchPlaceHolder = 'Search...';
  @Input() initialDropdownValue: Array<PrimeNgTableColumn<T>> = [];

  @Input() selectedFilters?: Array<Filter>;

  @Output() searchTermChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedColumnsChangedEvent: EventEmitter<Array<PrimeNgTableColumn<T>>> = new EventEmitter<
    Array<PrimeNgTableColumn<T>>
  >();
  @Output() showFiltersClickedEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() clearFiltersClickedEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  // Local values for service
  public selectedColumns: any = [];
  public searchTerm = '';

  constructor(@SkipSelf() public filterTableHeaderService: FilterTableHeaderService<T>) {
  }

  ngOnInit(): void {
    this.keepSelectedColumnsUpToDate();
    this.keepSearchTermUpToDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['dropdownOptions'] && !changes['dropdownOptions'].firstChange) {
      this.resetSelectedColumns();
    }
  }

  public showFilters(event: MouseEvent): void {
    event.stopPropagation();
    this.showFiltersClickedEvent.emit(event);
  }

  public clearFilters(event: MouseEvent): void {
    event.stopPropagation();
    this.filterTableHeaderService.clearSearchTerm();
    this.clearFiltersClickedEvent.emit(event);
  }

  public removeFilter(filter: Filter): void {
    this.filterTableHeaderService.removeFilter(filter);
  }

  public searchTermChanged(value: string): void {
    this.searchTermChangedEvent.emit(value);
  }

  public columnsToShowChanged(): void {
    this.selectedColumnsChangedEvent.emit(this.selectedColumns);
  }

  private resetSelectedColumns(): void {
    this.selectedColumnsChangedEvent.emit([]);
  }

  private keepSelectedColumnsUpToDate(): void {
    this.filterTableHeaderService.tableColumns.pipe(
      untilDestroyed(this)
    ).subscribe((value) => {
      this.selectedColumns = value;
    });
  }

  private keepSearchTermUpToDate(): void {
    this.filterTableHeaderService.searchTerm.pipe(
      untilDestroyed(this)
    ).subscribe((value) => {
      this.searchTerm = value;
    });
  }

}
