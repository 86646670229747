import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Address, SavedAddress} from '@shared/models/address.model';
import {DropdownOption} from '@shared/models/utility/option.model';
import {AddressService} from '@shared/services/address/address.service';
import {Subject, Observable, startWith} from 'rxjs';

@Component({
  selector: 'app-saved-address-selector',
  templateUrl: './saved-address-selector.component.html'
})
export class SavedAddressSelectorComponent {

  @Input({required: true}) prefix!: string;
  @Input() initialDisplayAddress?: Address;

  search$: Subject<string> = new Subject<string>();
  @Output() selectedAddress: EventEmitter<SavedAddress> = new EventEmitter<SavedAddress>();

  addressOptions$: Observable<Array<DropdownOption<SavedAddress>>>;

  constructor(private addressService: AddressService) {
    this.addressOptions$ = this.addressService.searchAddressesAsOptions(this.search$).pipe(startWith([]));
  }

  onAddressFilter(filter: string): void {
    if(filter?.length > 2) {
      this.search$.next(filter);
    }
  }

  onSelectionChange(address: DropdownOption<SavedAddress>) {
    this.selectedAddress.emit(address.value);
  }

}
