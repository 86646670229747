import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {getDimensionSuffix, getWeightSuffix, getVolumeSuffix} from '@core/utils/packaging-calculations';
import {Order} from '@shared/models/order.model';

@Component({
  selector: 'app-order-display-card',
  templateUrl: './order-display-card.component.html'
})
export class OrderDisplayCardComponent implements OnInit, OnChanges {

  @Input({required: true}) order!: Order;
  @Input() footer?: TemplateRef<any>;

  lengthSuffix: ' cm' | ' in' = ' cm';
  weightSuffix: ' kg' | ' lb' = ' kg';
  volumeSuffix: ' m' | ' in' = ' m';

  ngOnInit(): void {
    this.setSuffixValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['order'] && changes['order'].currentValue !== changes['order'].previousValue) {
      this.setSuffixValues();
    }
  }

  private setSuffixValues(): void {
    this.lengthSuffix = getDimensionSuffix(this.order.measurementSystem);
    this.weightSuffix = getWeightSuffix(this.order.measurementSystem);
    this.volumeSuffix = getVolumeSuffix(this.order.measurementSystem);
  }

}
