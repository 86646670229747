import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DocumentUploadEventPayload, DocumentUploadDto, Document} from '@shared/models/document.model';
import {DocumentService} from '@shared/services/document/document.service';
import {Observable, startWith} from 'rxjs';

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html'
})
export class DocumentComponent {

  @Input({required: true}) visible!: boolean;
  @Input({required: true}) loading!: boolean;
  uploadedFiles: File[] = [];
  selectedDocumentType: string | null = null;
  @Output() documentUploadEvent = new EventEmitter<DocumentUploadEventPayload>();
  @Output() documentDialogClose = new EventEmitter<void>();

  documentOptions$: Observable<Array<Document>>;

  constructor(
    private documentService: DocumentService
  ) {
    this.documentOptions$ = this.documentService.documents.pipe(startWith([]));
  }

  onFileSelect(files: File[]) {
    if(files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if(file) {
          this.uploadedFiles.push(file);
        }
      }
    }
  }

  onDialogHide(): void {
    this.uploadedFiles = [];
    this.selectedDocumentType = null;
    this.documentDialogClose.emit();
  }

  isUploadDisabled(): boolean {
    return this.selectedDocumentType !== null && this.uploadedFiles.length > 0;
  }

  showCancelButton(): boolean {
    return this.uploadedFiles.length > 0;
  }

  onClear(): void {
    this.uploadedFiles = [];
    this.visible = false;
    this.documentDialogClose.emit();
  }

  removeFile(file: File) {
    const index = this.uploadedFiles.indexOf(file);
    if(index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  onBeforeUpload(): void {
    const documentDtos: DocumentUploadDto[] = this.uploadedFiles.map((file) => ({
      name: file.name,
      type: this.selectedDocumentType
    }));

    this.documentUploadEvent.emit({documentDtos: documentDtos, uploadedFiles: this.uploadedFiles});
  }

}
