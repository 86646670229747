export const environment = {
  production: false,
  apiUrl: '/freight',
  keycloak: {
    config: {
      url: 'https://idp-sandbox.thehut.net/',
      realm: 'thg-central',
      clientId: 'wwl-freight-ui'
    }
  }
};
