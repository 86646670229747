import {Dimension} from '@shared/models/dimension.model';
import {
  IMPERIAL_VOLUME_WEIGHT_COEF,
  MeasurementSystem,
  METRIC_VOLUME_WEIGHT_COEF
} from '@shared/models/measurement-system.model';

export function calculateVolumetricWeight(volume: number, measurement: MeasurementSystem): number {
  return measurement === MeasurementSystem.METRIC ? volume / METRIC_VOLUME_WEIGHT_COEF : volume / IMPERIAL_VOLUME_WEIGHT_COEF;
}

export function calculateChargeableWeight(grossWeight: number, volumetricWeight: number): number {
  return grossWeight > volumetricWeight ? grossWeight : volumetricWeight;
}

export function calculateVolume(dimensions: Dimension, measurement: MeasurementSystem): number {
  return (dimensions.height * dimensions.width * dimensions.length) / (measurement === MeasurementSystem.METRIC ? 10 ** 6 : 1);
}

export function getWeightSuffix(value: MeasurementSystem): ' kg' | ' lb' {
  return value === MeasurementSystem.METRIC ? ' kg' : ' lb';
}

export function getVolumeSuffix(value: MeasurementSystem): ' m' | ' in' {
  return value === MeasurementSystem.METRIC ? ' m' : ' in';
}

export function getDimensionSuffix(value: MeasurementSystem): ' cm' | ' in' {
  return value === MeasurementSystem.METRIC ? ' cm' : ' in';
}
