import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {delayAtLeast, indicateLoading} from '@core/utils/rxjs-operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Shipment} from '@shared/models/shipment.model';
import {ShipmentTableHeaders} from '@shared/models/table.model';
import {Page, createEmptyPage} from '@shared/models/utility/page.model';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';
import {
  FilterTableHeaderService,
  LOCAL_STORAGE_KEY
} from '@shared/services/filter-table-header/filter-table-header.service';
import {PrimeNgSidebarService} from '@shared/services/prime-ng-sidebar/prime-ng-sidebar.service';
import {ShipmentService} from '@shared/services/shipment/shipment.service';
import {TableLazyLoadEvent} from 'primeng/table';
import {Subject, combineLatest, debounceTime} from 'rxjs';
import {ShipmentFiltersSidebarComponent} from '../shipment-filters-sidebar/shipment-filters-sidebar.component';

type Headers = Omit<ShipmentTableHeaders, 'shipmentNumber'>;

@UntilDestroy()
@Component({
  selector: 'app-shipment-selector',
  templateUrl: './shipment-selector.component.html',
  providers: [
    PrimeNgSidebarService,
    FilterTableHeaderService,
    {provide: LOCAL_STORAGE_KEY, useValue: 'AllocationShipmentTable'}
  ]
})
export class ShipmentSelectorComponent implements OnInit {

  @Output() selectEvent: EventEmitter<Shipment> = new EventEmitter<Shipment>();

  public shipmentList: Page<Shipment> = createEmptyPage();
  public shipmentListLoading$: Subject<boolean> = new Subject<boolean>();

  public columnDropdownOptions: Array<PrimeNgTableColumn<Headers>> = [
    {field: 'status', header: 'Status'},
    {field: 'collectionAddress', header: 'Collection Address'},
    {field: 'collectionCountry', header: 'Collection Country'},
    {field: 'deliveryAddress', header: 'Delivery Address'},
    {field: 'deliveryCountry', header: 'Delivery Country'},
    {field: 'expectedCollectionDate', header: 'Expected Collection Date'},
    {field: 'expectedDeliveryDate', header: 'Expected Delivery Date'},
    {field: 'createdDate', header: 'Created Date'},
    {field: 'createdBy', header: 'Created By'},
    {field: 'financeOwner', header: 'Finance Owner'}
  ];

  public initialColumnDropdownValues: Array<PrimeNgTableColumn<Headers>> = this.columnDropdownOptions;

  @ViewChild(ShipmentFiltersSidebarComponent) filtersSidebarComponent?: ShipmentFiltersSidebarComponent;

  constructor(
    public filterTableHeaderService: FilterTableHeaderService<Headers>,
    private shipmentService: ShipmentService,
    private sidebarService: PrimeNgSidebarService
  ) {
  }

  ngOnInit(): void {
    this.listenToRequestChanges();
    this.filterTableHeaderService.setAllPossibleTableColumns(this.initialColumnDropdownValues);
  }

  public clearFilters(): void {
    this.filtersSidebarComponent?.clearFilters();
  }

  public showFilters(): void {
    this.sidebarService.open();
  }

  public changePagination(value: TableLazyLoadEvent): void {
    this.filterTableHeaderService.pagination = {offset: value.first ?? 0, limit: value.rows ?? 10};
  }

  private listenToRequestChanges(): void {
    combineLatest([
      this.filterTableHeaderService.pagination,
      this.filterTableHeaderService.filters,
      this.filterTableHeaderService.searchTerm
    ]).pipe(
      untilDestroyed(this),
      debounceTime(0),
    )
      .subscribe(([pagination, filters, search]): void => {
        this.shipmentService.fetchShipmentList(pagination.offset, pagination.limit, filters, search).pipe(
          untilDestroyed(this),
          delayAtLeast(200),
          indicateLoading(this.shipmentListLoading$),
        )
          .subscribe((data: Page<Shipment>) => this.shipmentList = data);
      });
  }

}

