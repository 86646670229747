import {Directive, Input} from '@angular/core';

interface TableHeaderTemplateContext<TColumn extends object> {
  $implicit: TColumn;
}

@Directive({
  selector: '[appTableHeader]'
})
export class TableHeaderDirective<TColumn extends object> {

  @Input('appTableHeader') columns!: TColumn;

  static ngTemplateContextGuard<TContextColumn extends object>(
    directive: TableHeaderDirective<TContextColumn>,
    context: unknown
  ): context is TableHeaderTemplateContext<TContextColumn> {
    return true;
  }

}
