<p-sidebar [visible]="(isOpen | async) || false" position="right" [style]="{width: '400px'}" (onHide)="close()">
  <form [formGroup]="form">
    <div class="px-5">
      <div class="flex justify-between items-center mb-6">
        <h3 class="my-6 scroll-m-20 text-2xl font-semibold tracking-tight">Filter shipments</h3>
      </div>
      <div class="flex flex-col space-y-4 max-h-[calc(100vh-13rem)] overflow-y-scroll">
        <div>
          <div>
            <label for="status">Status</label>
          </div>
          <div>
            <p-multiSelect
              id="statuses"
              formControlName="statuses"
              placeholder="Select statuses"
              [filter]="true"
              [style]="{width: '100%'}"
              appendTo="body"
              [options]="shipmentStatus" />
          </div>
        </div>
        <div>
          <div>
            <label for="organisation">Organisation</label>
          </div>
          <div>
            <p-dropdown
              id="organisation"
              formControlName="organisation"
              placeholder="Select organisation"
              [options]="(organisations$ | async) || []"
              [filter]="true"
              [style]="{width: '100%'}"
              filterBy="name"
              appendTo="body"
              optionLabel="name"
              optionValue="id"/>
          </div>
        </div>
        <div>
          <div>
            <label for="created-by"> Created by</label>
          </div>
          <div>
            <p-dropdown
              id="created-by"
              formControlName="createdBy"
              placeholder="Select created by"
              [style]="{width: '100%'}"
              [filter]="true"
              filterBy="name"
              appendTo="body"
              [options]="(users$ | async) || []"
              optionLabel="name"
              optionValue="id"
            ></p-dropdown>
          </div>
        </div>
        <div>
          <div>
            <label for="collection-country">Collection country</label>
          </div>
          <div>
            <p-dropdown
              id="collection-country"
              formControlName="collectionCountry"
              placeholder="Select collection country"
              [style]="{width: '100%'}"
              [options]="(countries$ | async) || []"
              optionLabel="name"
              optionValue="id"
              appendTo="body"
              [filter]="true"></p-dropdown>
          </div>
        </div>
        <div>
          <div>
            <label for="delivery-country">Delivery country</label>
          </div>
          <div>
            <p-dropdown
              id="delivery-country"
              formControlName="deliveryCountry"
              placeholder="Select delivery country"
              [style]="{width: '100%'}"
              [options]="(countries$ | async) || []"
              optionLabel="name"
              optionValue="id"
              appendTo="body"
              [filter]="true"></p-dropdown>
          </div>
        </div>
        <div>
          <div>
            <label for="orderBy">Order by</label>
          </div>
          <div>
            <p-dropdown
              id="orderBy"
              formControlName="orderBy"
              placeholder="Select ordering"
              [style]="{width: '100%'}"
              appendTo="body"
              [options]="orderByOptions">
            </p-dropdown>
          </div>
        </div>
        <div>
          <div>
            <label for="financeOwner">Finance owner</label>
          </div>
          <div>
            <p-dropdown
              id="financeOwner"
              formControlName="financeOwner"
              placeholder="Select finance owner"
              [filter]="true"
              [style]="{width: '100%'}"
              appendTo="body"
              filterBy="name"
              optionLabel="label"
              optionValue="value"
              [options]="(financeOwners$ | async) || []">
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="relative flex px-4 mr-0 justify-end items-right left-0 right-0 bottom-0">
      <div class="my-6 flex space-x-4 mx-0" data-testid="form-buttons">
        <p-button label="Cancel" styleClass="p-button-sm p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Apply" styleClass="p-button-sm" (click)="apply()"></p-button>
      </div>
    </div>
  </form>
</p-sidebar>
