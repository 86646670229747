import {Component, Input} from '@angular/core';
import {Address} from '@shared/models/address.model';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html'
})
export class AddressCardComponent {

  @Input({required: true}) address!: Address;

}
