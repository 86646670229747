import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UrlInterceptor} from '@core/interceptor/url.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {HttpErrorInterceptor} from '@core/interceptor/http-error.interceptor';
import {ToastComponent} from '@shared/components/toasts/toast/toast.component';
import {ResponsiveHelperComponent} from '@shared/components/responsive-helper/responsive-helper.component';
import {SharedModule} from '@shared/shared.module';
import {initKeycloak} from '@core/utils/keycloak-init.factory';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';

@NgModule({
  declarations: [AppComponent, ResponsiveHelperComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      toastClass: '',
      toastComponent: ToastComponent
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
