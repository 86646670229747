import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Interval} from '@shared/models/interval.model';

@Component({
  selector: 'app-task-uptime',
  templateUrl: './task-uptime.component.html'
})
export class TaskUptimeComponent implements OnChanges {

  @Input() uptime?: Interval;
  public severity: 'success' | 'info' | 'warning' | 'danger' = 'success';

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes['uptime'] && changes['uptime'].previousValue !== changes['uptime'].currentValue) {
      this.calculateSeverity(changes['uptime'].currentValue);
    }
  }

  private calculateSeverity(uptime: Interval): void {
    if(uptime.days >= 5) this.severity = 'danger';
    else if(uptime.days < 5 && uptime.days >= 2) this.severity = 'warning';
    else if(uptime.days < 2) this.severity = 'success';
    else this.severity = 'info';
  }

}
