import {OrderType} from '@shared/models/order.model';
import {Organisation, User} from '@shared/models/user.model';
import {Country} from '@shared/models/country.model';

export interface OrderFilters {
  statuses: Array<string>;
  type: OrderType;
  organisation: Organisation['id'];
  createdBy: User['id'];
  collectionCountry: Country['id'];
  deliveryCountry: Country['id'];
  taskAssignedTo: User['id'];
  orderBy: OrderListOrdering;
}

export enum OrderListOrdering {
  CREATED_DATE = 'CREATED_DATE',
  RANKING = 'RANKING',
  UPDATED_DATE = 'LAST_MODIFIED'
}
