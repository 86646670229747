import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) {
  }

  public showSuccessToast(description: string, title = 'Success'): void {
    this.toastr.success(description, title);
  }

  public showErrorToast(description = 'Sorry something has gone wrong', title = 'Error'): void {
    this.toastr.error(description, title);
  }

  public showInfoToast(description: string, title = 'Info'): void {
    this.toastr.info(description, title);
  }

  public showWarningToast(description: string, title = 'Warning'): void {
    this.toastr.warning(description, title);
  }

}
