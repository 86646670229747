import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  Validators
} from '@angular/forms';
import {Address} from '@shared/models/address.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Warehouse} from '@shared/models/warehouse';
import {debounceTime, skip} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-warehouse-address-form',
  templateUrl: './warehouse-address-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WarehouseAddressFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WarehouseAddressFormComponent),
      multi: true
    }
  ]
})
export class WarehouseAddressFormComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input({required: true}) data: Array<Warehouse> = [];
  @Input() label = 'Address';
  @Input() readOnly = false;
  @Input() orderAddress?: Address;

  @Output() selectedWarehouse: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();

  public warehouseAddressForm: FormGroup;

  address?: Address;

  constructor(
    private fb: FormBuilder,
  ) {
    this.warehouseAddressForm = this.createFormGroup();
  }

  ngOnInit(): void {
    this.listenToWarehouseChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['orderAddress'] && changes['orderAddress'].currentValue !== changes['orderAddress'].previousValue) {
      this.address = changes['orderAddress'].currentValue;
    }
  }

  onTouch(): void {
    this.warehouseAddressForm.markAllAsTouched();
  }

  registerOnChange(fn: any): void {
    this.warehouseAddressForm.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.warehouseAddressForm.disable() : this.warehouseAddressForm.enable();
  }

  writeValue(warehouse: { select: string }): void {
    warehouse && this.warehouseAddressForm.setValue({select: warehouse.select}, {emitEvent: false});
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.warehouseAddressForm.valid ? null : {
      invalidForm: {
        valid: false,
        message: 'Address Form fields are invalid'
      }
    };
  }

  private createFormGroup(warehouse?: Warehouse): FormGroup {
    return this.fb.group({
      select: [warehouse ?? null, [Validators.required]]
    });
  }

  private listenToWarehouseChanges(): void {
    this.warehouseAddressForm.get('select')?.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(0),
      skip(1),
    ).subscribe((warehouseId: string | null) => this.updateAddressFields(warehouseId));
  }

  private updateAddressFields(warehouseId: string | null): void {
    if(!warehouseId) {
      this.address = undefined;
      return;
    }
    const warehouse = this.data.find((item: Warehouse) => item.id === warehouseId);
    this.address = warehouse ? warehouse.savedAddress.address : undefined;
  }

  public onWarehouseSelection(id: string) {
    const selected = this.data.find((warehouse) => warehouse.id === id);
    if(selected) this.selectedWarehouse.emit(selected);
  }

}
