import {Component, Input} from '@angular/core';
import {ShipmentTableHeaders} from '@shared/models/table.model';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';

type Headers = Omit<ShipmentTableHeaders, 'shipmentNumber'>;

@Component({
  selector: '[app-shipment-selection-header]',
  templateUrl: './shipment-selection-header.component.html'
})
export class ShipmentSelectionHeaderComponent {

  @Input({required: true}) headers!: Array<PrimeNgTableColumn<Headers>>;

}
