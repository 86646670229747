import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ShipmentStatus} from '@shared/models/shipment.model';
import {StatusStyle} from '@shared/models/status.model';
import {ShipmentService} from '@shared/services/shipment/shipment.service';

@Component({
  selector: 'app-shipment-status-tag',
  templateUrl: './shipment-status-tag.component.html'
})
export class ShipmentStatusTagComponent implements OnChanges {

  @Input() status?: ShipmentStatus;
  @Input() styled = true;

  statusStyle!: StatusStyle;

  constructor(private shipmentService: ShipmentService) {
  }

  private setStatus(): void {
    if(!this.status) return;
    this.statusStyle = this.shipmentService.fetchShipmentStatusStyling(this.status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['status'] && changes['status'].previousValue !== changes['status'].currentValue) this.setStatus();
  }

}
