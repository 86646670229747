export enum DimensionUom {
  M = 'M',
  MM = 'MM',
  CM = 'CM',
  IN = 'IN',
  FT = 'FT'
}

export interface Dimension {
  length: number;
  width: number;
  height: number;
  unit: DimensionUom;
}
