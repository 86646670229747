import {Directive, Input} from '@angular/core';

// Implicit here is just the default value (the one you don't have to specify in the template)
interface TableRowTemplateContext<TItem extends object, TColumn extends object> {
  // $implicit: TItem;
  $implicit: TItem;
  columns: TColumn;
}

@Directive({
  selector: 'ng-template[appTableRow]'
})
export class TableRowDirective<TItem extends object, TColumn extends object> {

  @Input('appTableRow') items!: TItem[];
  @Input() rowColumns!: TColumn;

  static ngTemplateContextGuard<TContextItem extends object, TContextColumn extends object>(
    directive: TableRowDirective<TContextItem, TContextColumn>,
    context: unknown
  ): context is TableRowTemplateContext<TContextItem, TContextColumn> {
    return true;
  }

}
