<div class="min-w-[250px] rounded-lg shadow-xs overflow-hidden bg-transparent">
  <div class="p-4 flex items-center">
    <div
      class="p-2">
      <lucide-angular name="map-pin" class="text-primary"></lucide-angular>
    </div>
    <div>
      <p class="font-semibold text-muted-foreground dark:text-foreground">
        {{address.addressLine1}}, {{address.addressLine2}}, {{address.zone}}, {{address.city}},
        {{address.country}}, {{address.postalCode}}
      </p>
    </div>
  </div>
</div>
