import {DropdownOption} from './option.model';

export const BOOLEAN_OPTIONS: Array<DropdownOption<boolean>> = [
  {
    label: 'True',
    value: true
  },
  {
    label: 'False',
    value: false
  }
];
