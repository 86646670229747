<div class="min-w-[250px] rounded-lg shadow-xs overflow-hidden bg-transparent">
  <div class="p-4 flex items-center">
    <div
      class="p-2 rounded-full text-primary mr-4">
      <ng-content></ng-content>
    </div>
    <div>
      <p class="mb-2 text-sm font-medium text-muted-foreground dark:text-foreground">
        {{heading}}
      </p>
      <p class="text-lg font-semibold text-muted-foreground dark:text-foreground">
        {{value}}
      </p>
    </div>
  </div>
</div>
