import {Pipe, PipeTransform} from '@angular/core';
import {SubMenuItem} from '@shared/models/menu.model';
import {UserService} from '@shared/services/user/user.service';

@Pipe({
  name: 'permission'
})
export class PermissionPipe implements PipeTransform {

  constructor(private userService: UserService) {}

  async transform(value: Array<SubMenuItem> | undefined): Promise<Array<SubMenuItem> | undefined> {
    if(!value) {
      return value;
    }

    const result = await Promise.all(value.map((item) => this.userService.hasPermissions(item.permissions ?? new Set())));
    return value.filter((_, idx) => result[idx]);
  }

}
