<div #filters class="py-5">
  <div class="flex gap-2 pb-2 w-full">
    <div class="flex-none" *ngIf="dropdownOptions.length > 0">
      <p-multiSelect
        [options]="dropdownOptions"
        [(ngModel)]="selectedColumns"
        [style]="filters.offsetWidth < 600 ? {'width': '42px'} : {}"
        (onChange)="this.columnsToShowChanged()"
        optionLabel="header"
        selectedItemsLabel="{0} columns selected"
        placeholder="Select Columns">
      </p-multiSelect>
    </div>
    <div class="grow">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search"></i>
        <input
          type="text"
          [placeholder]="searchPlaceHolder"
          class="w-full"
          pInputText
          [ngModel]="filterTableHeaderService.searchTerm | async"
          (ngModelChange)="searchTerm = $event"
          (keydown.enter)="searchTermChanged(searchTerm)"
        />
      </span>
    </div>
    <ng-container *ngIf="allowFiltering">
      <div class="flex-none" *ngIf="selectedFilters">
        <p-button styleClass="p-button-sm" [text]="true" (click)="clearFilters($event)">
          <i-lucide name="filter-x" class="h-6"></i-lucide>
          <div class="font-semibold md:block sm:hiden" *ngIf="filters.offsetWidth > 600">
            Clear filters
          </div>
        </p-button>
      </div>
      <div class="flex-none">
        <p-button styleClass="p-button-sm" [text]="true" (click)="showFilters($event)">
          <i-lucide name="filter" class="h-6"></i-lucide>
          <div class="font-semibold md:block sm:hiden" *ngIf="filters.offsetWidth > 600">
            Filters
          </div>
        </p-button>
      </div>
    </ng-container>
  </div>
  <div class="flex gap-4 w-full flex-wrap" *ngIf="selectedFilters">
    <div class="flex-none" *ngFor="let filter of selectedFilters">
      <app-filter-chip
        [filter]="filter"
        (removeClicked)="removeFilter($event)"
      ></app-filter-chip>
    </div>
  </div>
</div>
