import {Pipe, PipeTransform} from '@angular/core';
import {Currency} from '@shared/models/currency.model';

@Pipe({
  name: 'convertToGbp'
})
export class ConvertToGbpPipe implements PipeTransform {

  transform(value: number | undefined, currencyCode: string | undefined, currencies: Array<Currency>): number {
    if(value === undefined || currencyCode === undefined) return NaN;
    if(currencyCode !== 'GBP') {
      const currency = currencies.find((element) => element.id === currencyCode);
      if(currency && currency.rateToGbp) {
        return value / currency.rateToGbp;
      } else {
        return NaN;
      }
    }
    return value;
  }

}
