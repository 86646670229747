import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddressPipe} from '@shared/pipes/address/address.pipe';
import {LuxonDatePipe} from '@shared/pipes/luxon/luxon-date.pipe';
import {PercentagePipe} from '@shared/pipes/percentage/percentage.pipe';
import {InitialsPipe} from '@shared/pipes/user/initials.pipe';
import {FullnamePipe} from '@shared/pipes/user/fullname.pipe';
import {PermissionPipe} from '@shared/pipes/permission/permission.pipe';
import {DimensionPipe} from './dimension/dimension.pipe';
import {WeightPipe} from './weight/weight.pipe';
import {IndexOfPipe} from './index/index-of.pipe';
import {ContactInfoPipe} from './contact-info/contact-info.pipe';
import {IntervalPipe} from './interval/interval.pipe';
import {CastPipe} from './cast/cast.pipe';
import {TitlecaseWithSpacePipe} from './titlecase-with-space/titlecase-with-space.pipe';
import {CountPalletsPipe} from './packaging-calculations/count-pallets.pipe';
import {CountBoxesPipe} from './packaging-calculations/count-boxes.pipe';
import {GrossWeightPipe} from './packaging-calculations/gross-weight.pipe';
import {VolumePipe} from './packaging-calculations/volume.pipe';
import {ConvertToGbpPipe} from '@shared/pipes/convert-to-gbp/convert-to-gbp.pipe';

const PIPES = [
  AddressPipe,
  LuxonDatePipe,
  PercentagePipe,
  InitialsPipe,
  FullnamePipe,
  PermissionPipe,
  DimensionPipe,
  WeightPipe,
  IndexOfPipe,
  ContactInfoPipe,
  CastPipe,
  IntervalPipe,
  TitlecaseWithSpacePipe,
  CountPalletsPipe,
  CountBoxesPipe,
  GrossWeightPipe,
  VolumePipe,
  ConvertToGbpPipe
];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  exports: [...PIPES]
})
export class PipesModule {
}
