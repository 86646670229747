<div *ngIf="address && readOnly">
  <label for="address">{{ prefix }} Address</label>
  <div class="mt-2">
    <input
      type="text"
      pInputText
      class="w-full"
      [value]="address | address" readOnly="true"/>
  </div>
</div>
<div [formGroup]="addressForm">
  <p-button
    *ngIf="!readOnly"
    label="Or manual address"
    [link]="true"
    (onClick)="toggleManualAddresses()"/>

  <div class="sm:col-span-3 border-t border-b border-border p-10" [hidden]="!manualAddresses">
    <app-form-field>
      <label for="name">{{ prefix }} Address Name</label>
      <div class="mt-2">
        <input
          id="name"
          appShowError
          pInputText
          [readOnly]="readOnly"
          class="w-full"
          formControlName="name"
          placeholder="Enter address name"/>
      </div>
    </app-form-field>
    <div class="pt-6">
      <app-form-field>
        <label for="address-line-1">{{ prefix }} Address Line 1</label>
        <div class="mt-2">
          <input
            id="address-line-1"
            appShowError
            pInputText
            [readOnly]="readOnly"
            class="w-full"
            formControlName="addressLine1"
            placeholder="Enter address line 1"/>
        </div>
      </app-form-field>
    </div>
    <div class="pt-6">
      <app-form-field>
        <label for="address-line-2">{{ prefix }} Address Line 2</label>
        <div class="mt-2">
          <input
            id="address-line-2"
            appShowError
            pInputText
            [readOnly]="readOnly"
            class="w-full"
            formControlName="addressLine2"
            placeholder="Enter address line 2"/>
        </div>
      </app-form-field>
    </div>
    <div class="pt-6">
      <app-form-field>
        <label for="county">{{ prefix }} County</label>
        <div class="mt-2">
          <input
            id="county"
            appShowError
            pInputText
            [readOnly]="readOnly"
            class="w-full"
            formControlName="zone"
            placeholder="Select a country..."/>
        </div>
      </app-form-field>
    </div>
    <div class="pt-6">
      <app-form-field>
        <label for="city">{{ prefix }} City </label>
        <div class="mt-2">
          <input
            id="city"
            appShowError
            pInputText
            [readOnly]="readOnly"
            class="w-full"
            formControlName="city"
            placeholder="Enter a city"/>
        </div>
      </app-form-field>
    </div>
    <div class="pt-6">
      <app-form-field>
        <label for="postal-code">{{ prefix }} Postal Code </label>
        <div class="mt-2">
          <input
            id="postal-code"
            appShowError
            pInputText
            [readOnly]="readOnly"
            class="w-full"
            formControlName="postalCode"
            placeholder="Enter a postal code"/>
        </div>
      </app-form-field>
    </div>
    <div class="pt-6">
      <app-form-field>
        <label for="country">{{ prefix }} Country</label>
        <div class="mt-2" *ngIf="countryOptions$ | async as options">
          <p-dropdown
            id="country"
            appShowError
            optionLabel="name"
            [options]="options"
            [showClear]="true"
            [readonly]="readOnly"
            [filter]="true"
            optionValue="id"
            filterBy="name"
            formControlName="country"
            [style]="{width: '100%'}"
            placeholder="Select a country..."/>
        </div>
      </app-form-field>
    </div>
  </div>
</div>
