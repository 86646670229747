import {Component, Input} from '@angular/core';
import {Shipment} from '@shared/models/shipment.model';
import {ShipmentTableHeaders} from '@shared/models/table.model';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';

type Headers = Omit<ShipmentTableHeaders, 'shipmentNumber'>;

@Component({
  selector: '[app-shipment-selection-row]',
  templateUrl: './shipment-selection-row.component.html'
})
export class ShipmentSelectionRowComponent {

  @Input({required: true}) data!: Shipment;
  @Input({required: true}) headers!: PrimeNgTableColumn<Headers>[];

}
