import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {SessionUser, UserForm} from '@shared/models/user.model';
import {DropdownOption} from '@shared/models/utility/option.model';
import {OrganisationService} from '@shared/services/organisation/organisation.service';
import {RoleService} from '@shared/services/roles/role.service';
import {Observable, startWith} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit, AfterViewInit {

  @Input() user?: SessionUser;
  @Input() restricted = false;
  @Input() emailOverride?: string;
  @Input() readOnly = false;
  @Input() apiRequestInProgress = false;

  @Output() submitEvent: EventEmitter<UserForm> = new EventEmitter<UserForm>();
  @Output() cancelEvent: EventEmitter<UserForm> = new EventEmitter<UserForm>();

  organisationOptions$: Observable<Array<DropdownOption<string>>>;
  roleOptions$: Observable<Array<DropdownOption<string>>>;
  userForm!: FormGroup;
  formValueBeforeEdit?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private organisationService: OrganisationService,
    private roleService: RoleService
  ) {
    this.organisationOptions$ = this.organisationService.fetchOrganisationOptions().pipe(startWith([]));
    this.roleOptions$ = this.roleService.fetchRoleOptions()
      .pipe(startWith([]));
  }

  ngOnInit(): void {
    this.userForm = this.createFormGroup(this.user);
  }

  ngAfterViewInit(): void {
    this.snapshotFormValues();
  }

  createFormGroup(user?: SessionUser): FormGroup {
    const roleIds = user ? user.roles.map((role) => role.id) : [];
    const organisationIds = user ? user.organisations.map((org) => org.id) : [];
    const email = this.emailOverride ?? user?.email;

    return this.fb.group({
      firstName: [user?.firstName ?? null, [Validators.required, Validators.minLength(0), Validators.maxLength(64)]],
      lastName: [user?.lastName ?? null, [Validators.required, Validators.minLength(0), Validators.maxLength(64)]],
      email: [email ?? null, [Validators.required, Validators.minLength(0), Validators.maxLength(255), Validators.email]],
      roles: [roleIds, [Validators.required]],
      organisations: [organisationIds, [Validators.required]],
      active: [user?.active ?? true, [Validators.required]],
      verified: [user?.verified ?? false, [Validators.required]]
    });
  }

  onSubmit(): void {
    if(this.userForm.valid) {
      this.submitEvent.emit(this.userForm.value);
    }
  }

  onCancel(): void {
    this.resetFormData();
    this.cancelEvent.emit(this.userForm.value);
  }

  private resetFormData(): void {
    if(this.formValueBeforeEdit) this.userForm.patchValue(this.formValueBeforeEdit);
    else window.location.reload();
  }

  private snapshotFormValues(): void {
    this.formValueBeforeEdit = this.userForm.getRawValue();
  }

}
