import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast-wrapper.component.html'
})
export class CustomToastWrapperComponent {

  @Input() title? = '';
  @Input() message?: string | null = '';
  @Input() progressBar? = true;
  @Input() width?: number;

}
