import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, shareReplay} from 'rxjs';
import {Country} from '../../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private url = '/api/country';

  private readonly countries$: Observable<Array<Country>>;

  constructor(private _httpClient: HttpClient) {
    this.countries$ = this._httpClient.get<Array<Country>>(this.url)
      .pipe(shareReplay());
  }

  get countries(): Observable<Array<Country>> {
    return this.countries$;
  }

}
