<div class="relative w-full min-w-[300px] mx-auto sm:max-w-lg rounded-lg bg-background shadow-2xl {{themeService.theme}}" >
  <app-error-toast
    *ngIf="toastPackage.toastType === 'toast-error'"
    [title]="title"
    [message]="message"
    [width]="width"
  ></app-error-toast>
  <app-success-toast
    *ngIf="toastPackage.toastType === 'toast-success'"
    [title]="title"
    [message]="message"
    [width]="width"
  ></app-success-toast>
  <app-info-toast
    *ngIf="toastPackage.toastType === 'toast-info'"
    [title]="title"
    [message]="message"
    [width]="width"
  ></app-info-toast>
  <app-warning-toast
    *ngIf="toastPackage.toastType === 'toast-warning'"
    [title]="title"
    [message]="message"
    [width]="width"
  ></app-warning-toast>
</div>
