import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number | undefined, total: number | undefined): string {
    if(total === 0) return 'Unknown %';
    if(!value || !total) return 'Unknown %';
    return `${((value / total) * 100).toFixed(2)}%`;
  }

}
