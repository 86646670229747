<label for="address">{{ prefix }} Address</label>
<div class="mt-2">
  <p-dropdown
    *ngIf="addressOptions$ | async as options"
    id="address"
    [autoDisplayFirst]="false"
    [options]="options"
    optionLabel="label"
    dataKey="value"
    [filter]="true"
    filterBy="label"
    (onFilter)="onAddressFilter($event.filter)"
    (onChange)="onSelectionChange($event.value)"
    [autofocusFilter]="true"
    [placeholder]="initialDisplayAddress ? (initialDisplayAddress | address) : 'Select an address...'"
    [style]="{width: '100%'}"/>
</div>
