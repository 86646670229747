import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DimensionUom} from 'src/app/shared/models/dimension.model';
import {InteriorCargoType} from 'src/app/shared/models/interior-cargo-type.enum';
import {DropdownOption} from '@shared/models/utility/option.model';
import {Package} from 'src/app/shared/models/package.model';
import {WeightUom} from 'src/app/shared/models/weight.model';
import {PackageService} from 'src/app/shared/services/package/package.service';
import {BehaviorSubject, combineLatest, EMPTY, map, Observable} from 'rxjs';
import {MeasurementSystem} from '@shared/models/measurement-system.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  calculateChargeableWeight,
  calculateVolume,
  calculateVolumetricWeight,
  getDimensionSuffix,
  getVolumeSuffix,
  getWeightSuffix
} from '@core/utils/packaging-calculations';

@UntilDestroy()
@Component({
  selector: 'app-packaging-form',
  templateUrl: './packaging-form.component.html'
})
export class PackagingFormComponent implements OnInit, OnChanges {

  @Input({required: true}) parentForm!: FormGroup;
  @Input({required: true}) measurementMetric: MeasurementSystem = MeasurementSystem.METRIC;
  @Input() packages?: Array<Package>;
  @Input() readOnly = false;

  packagingForm?: FormGroup;
  interiorCargoTypeOptions?: Array<DropdownOption<InteriorCargoType>>;
  measurementMetric$: BehaviorSubject<MeasurementSystem> = new BehaviorSubject<MeasurementSystem>(MeasurementSystem.METRIC);

  lengthSuffix: ' cm' | ' in' = ' cm';
  weightSuffix: ' kg' | ' lb' = ' kg';
  volumeSuffix: ' m' | ' in' = ' m';

  palletCount$: Observable<number> = EMPTY;
  boxCount$: Observable<number> = EMPTY;
  volume$: Observable<number> = EMPTY;
  grossWeight$: Observable<number> = EMPTY;
  volumetricWeight$: Observable<number> = EMPTY;
  chargeableWeight$: Observable<number> = EMPTY;

  constructor(
    private fb: FormBuilder,
    private packageService: PackageService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['packages'] && changes['packages'].currentValue?.length > 0 && !changes['packages'].firstChange && changes['packages'].currentValue !== changes['packages'].previousValue) {
      this.addExistingPackagesToForm(changes['packages'].currentValue);
    }
    if(changes['measurementMetric'] && changes['measurementMetric'].currentValue !== changes['measurementMetric'].previousValue) {
      this.measurementMetric$.next(changes['measurementMetric'].currentValue);
    }
  }

  ngOnInit(): void {
    this.interiorCargoTypeOptions = this.packageService.fetchInteriorCargoTypes();
    this.createPackagingForm(this.packages);
    this.assignPackagingCalculations();
    this.listenToMeasurementMetricChanges();
  }

  addRow(data?: Package): void {
    const control = this.tableRows;
    data ? control.push(this.createFormGroup(data)) : control.push(this.createFormGroup());
  }

  get tableRows(): FormArray {
    return this.packagingForm?.get('packaging') as FormArray;
  }

  onDeleteRow(index: number): void {
    this.tableRows.removeAt(index);
  }

  private createPackagingForm(packages?: Array<Package>): void {
    this.packagingForm = this.fb.group({
      packaging: this.fb.array([], [Validators.required])
    });
    this.attachFormGroupToParent();
    packages ? this.addExistingPackagesToForm(packages) : this.addRow();
  }

  private attachFormGroupToParent(): void {
    if(this.parentForm) {
      this.parentForm.setControl('packageStructure', this.packagingForm);
      this.packagingForm?.setParent(this.parentForm);
    }
  }

  private createFormGroup(data?: Package): FormGroup {
    return this.fb.group({
      interiorCargoType: [data?.interiorCargoType ? data.interiorCargoType.toString() : null, [Validators.required]],
      quantity: [data?.quantity ?? null, [Validators.required, Validators.min(0)]],
      dimension: this.fb.group({
        length: [data?.dimension.length ?? null, [Validators.required, Validators.min(0)]],
        width: [data?.dimension.width ?? null, [Validators.required, Validators.min(0)]],
        height: [data?.dimension.height ?? null, [Validators.required, Validators.min(0)]],
        unit: [data?.dimension.unit ?? DimensionUom.CM, [Validators.required]]
      }),
      weight: this.fb.group({
        value: [data?.weight.value ?? null, [Validators.required, Validators.min(0)]],
        unit: [data?.weight.unit ?? WeightUom.KG, [Validators.required]]
      }),
      interiorPackages: [data?.interiorPackages ?? []]
    });
  }

  private addExistingPackagesToForm(packages: Array<Package>): void {
    const control: FormArray = this.tableRows;
    if(control) control.clear();
    packages?.forEach((data: Package) => {
      control.push(this.createFormGroup(data));
    });
  }

  private updateMetricDisplayValues(system: MeasurementSystem): void {
    this.lengthSuffix = getDimensionSuffix(system);
    this.weightSuffix = getWeightSuffix(system);
    this.volumeSuffix = getVolumeSuffix(system);
  }

  private assignPackagingCalculations(): void {
    this.palletCount$ = this.calculatePalletCount();
    this.boxCount$ = this.calculateBoxCount();
    this.volume$ = this.calculateVolume();
    this.grossWeight$ = this.calculateGrossWeight();
    this.volumetricWeight$ = this.calculateVolumetricWeight();
    this.chargeableWeight$ = this.calculateChargeableWeight();
    this.packagingForm?.updateValueAndValidity();
    // This is really hacky and a better solution should be explored
    // Currently required for the observable values to correctly sync at the start.
    setTimeout(() => {
      this.tableRows.updateValueAndValidity();
    });
  }

  private listenToMeasurementMetricChanges(): void {
    this.measurementMetric$.pipe(untilDestroyed(this)).subscribe(
      (value) => this.updateMetricDisplayValues(value)
    );
  }

  // Sum of pallets in form
  // Pallets * Quantity for each row summed
  private calculatePalletCount(): Observable<number> {
    return this.tableRows.valueChanges.pipe(
      untilDestroyed(this),
      map((data) => data.filter((row: Package) => row.interiorCargoType === 'PALLET')),
      map((data) => data.reduce((accumulator: number, row: Package) => accumulator + row.quantity, 0))
    );
  }

  // Sum of boxes in form
  // Boxes * Quantity for each row summed
  private calculateBoxCount(): Observable<number> {
    return this.tableRows.valueChanges.pipe(
      untilDestroyed(this),
      map((data) => data.filter((row: Package) => row.interiorCargoType === 'BOX')),
      map((data) => data.reduce((accumulator: number, row: Package) => accumulator + row.quantity, 0))
    );
  }

  // Sum of volume's calculated for each row
  // (width * height * length) * quantity for each row summed
  // Volume for metric converted to cubic meters which is 10 ^ 6 smaller
  private calculateVolume(): Observable<number> {
    return combineLatest([this.tableRows.valueChanges, this.measurementMetric$]).pipe(
      untilDestroyed(this),
      map(([data, measurement]) => data.reduce((volume: number, row: Package) => volume + (row.quantity * calculateVolume(row.dimension, measurement)), 0))
    );
  }

  // Sum of Weight Per Packs
  // Weight Per Pack * Quantity for each row summed
  private calculateGrossWeight(): Observable<number> {
    return this.tableRows.valueChanges.pipe(
      untilDestroyed(this),
      map((data) => data.reduce((weight: number, row: Package) => weight + (row.weight.value * row.quantity), 0))
    );
  }

  // Volume / Constant
  private calculateVolumetricWeight(): Observable<number> {
    return combineLatest([this.volume$, this.measurementMetric$]).pipe(
      untilDestroyed(this),
      map(([volume, measurement]) => calculateVolumetricWeight(volume, measurement))
    );
  }

  // Largest value grossWeight vs volumetricWeight
  private calculateChargeableWeight(): Observable<number> {
    return combineLatest([this.grossWeight$, this.volumetricWeight$]).pipe(
      untilDestroyed(this),
      map(([grossWeight, volumetricWeight]) => calculateChargeableWeight(grossWeight, volumetricWeight))
    );
  }

}
