import {Injectable} from '@angular/core';
import {InteriorCargoType} from '../../models/interior-cargo-type.enum';
import {DropdownOption} from '../../models/utility/option.model';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  public fetchInteriorCargoTypes(): Array<DropdownOption<InteriorCargoType>> {
    return [
      {label: 'Pallet', value: InteriorCargoType.PALLET},
      {label: 'Box', value: InteriorCargoType.BOX}
    ];
  }

}
