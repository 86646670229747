import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, SkipSelf} from '@angular/core';
import {PrimeNgSidebarService} from '@shared/services/prime-ng-sidebar/prime-ng-sidebar.service';
import {Filter, FilterValue} from '@shared/models/utility/filter';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter, map, Observable, of, shareReplay, startWith, tap} from 'rxjs';
import {Country} from '@shared/models/country.model';
import {DropdownOption} from '@shared/models/utility/option.model';
import {Organisation, SessionUser, User} from '@shared/models/user.model';
import {CountryService} from '@shared/services/country/country.service';
import {UserService} from '@shared/services/user/user.service';
import {ShipmentService} from '@shared/services/shipment/shipment.service';
import {Shipment, ShipmentStatus} from '@shared/models/shipment.model';
import {convertArrayOfFiltersToObject, createDisplayFilter, createListDisplayFilter} from '@core/utils/filters';
import {ShipmentFilters, ShipmentListOrdering} from '@modules/dashboard/modules/shipment/models/shipment-filters';
import {FilterTableHeaderService} from '@shared/services/filter-table-header/filter-table-header.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BOOLEAN_OPTIONS} from '@shared/models/utility/boolean';
import {OrganisationService} from '@shared/services/organisation/organisation.service';
import {PermissionKey} from '@shared/models/permission-key.model';

@UntilDestroy()
@Component({
  selector: 'app-shipment-filters-sidebar',
  templateUrl: './shipment-filters-sidebar.component.html'
})
export class ShipmentFiltersSidebarComponent implements OnInit {

  @Output() applyFiltersEvent: EventEmitter<Array<Filter>> = new EventEmitter<Array<Filter>>();

  public countries$?: Observable<Array<Country>>;
  public shipmentStatus?: Array<DropdownOption<ShipmentStatus>>;
  public users$?: Observable<Array<User>>;
  public booleanOptions = BOOLEAN_OPTIONS;
  public organisations$?: Observable<Array<Organisation>>;
  public orderByOptions?: Array<DropdownOption<ShipmentListOrdering>>;
  public financeOwners$?: Observable<Array<DropdownOption<string>>>;

  private users: Array<User> = [];
  private countries: Array<Country> = [];
  private organisations: Array<Organisation> = [];

  public isOpen: Observable<boolean> = of(false);

  public _form: FormGroup = this.fb.group({
    statuses: [null],
    organisation: [null],
    createdBy: [null],
    collectionCountry: [null],
    deliveryCountry: [null],
    orderBy: [null],
    financeOwner: [null]
  });

  constructor(
    private sidebarService: PrimeNgSidebarService,
    private fb: FormBuilder,
    private countryService: CountryService,
    private shipmentService: ShipmentService,
    private organisationService: OrganisationService,
    private userService: UserService,
    @SkipSelf() private filterTableHeaderService: FilterTableHeaderService<Shipment>,
    private _cdr: ChangeDetectorRef
  ) {
    this.countries$ = this.countryService.countries.pipe(
      startWith([]),
      shareReplay(),
      tap((countries) => this.countries = countries)
    );
    this.shipmentStatus = this.shipmentService.fetchShipmentStatus();
    this.users$ = this.userService.fetchAllUsers().pipe(
      startWith([]),
      tap((users) => this.users = users)
    );
    this.organisations$ = this.userService.user.pipe(
      map((user: SessionUser) => user.organisations),
      tap((organisations) => this.organisations = organisations)
    );
    this.orderByOptions = this.shipmentService.fetchShipmentListOrderingOptions();
    this.organisations$?.subscribe();
  }

  ngOnInit(): void {
    this.financeOwners$ = this.organisationService.fetchUsersByOrganisationsAndPermissions(this.organisations.map(
      (organisation) => organisation.id), [PermissionKey.APPROVE_COSTING]
    );
    this.isOpen = this.sidebarService.isOpen;
    this.keepFormValuesUpToDate();
    this.listenToOrganisationChanges();
  }

  public close(): void {
    this.sidebarService.close();
  }

  public apply(): void {
    this.applyFiltersEvent.emit(this.buildArrayOfFiltersFromFormValues(this._form.value));
    this.close();
  }

  public cancel(): void {
    this.rollbackFormChanges();
    this.close();
  }

  public clearFilters(): void {
    this._form.reset();
    this.apply();
  }

  get form() {
    return this._form;
  }

  private rollbackFormChanges(): void {
    if(this.filterTableHeaderService.getFiltersAsValues().length > 0) {
      const valuesForFormReset: Record<string, FilterValue> = convertArrayOfFiltersToObject(this.filterTableHeaderService.getFiltersAsValues());
      this._form.patchValue(valuesForFormReset);
    } else {
      this.clearFilters();
    }
  }

  private patchFormValuesFromServiceValues(serviceValues: Array<Filter>): void {
    const valuesForFormReset: Record<string, FilterValue> = convertArrayOfFiltersToObject(serviceValues);
    this._form.reset();
    this._form.patchValue(valuesForFormReset);
  }

  private buildArrayOfFiltersFromFormValues(formValues: ShipmentFilters): Array<Filter> {
    const createdBy = this.users.find((user) => user.id === formValues.createdBy);
    const organisation = this.organisations.find((org) => org.id === formValues.organisation);
    const collectionCountry = this.countries.find((country) => country.id === formValues.collectionCountry);
    const deliveryCountry = this.countries.find((country) => country.id === formValues.deliveryCountry);
    const financeOwner = this.users.find((user) => user.id === formValues.financeOwner);
    return [
      createListDisplayFilter('statuses', formValues.statuses, 'Statuses', formValues.statuses),
      createDisplayFilter('createdBy', createdBy?.id || '', 'Created By', createdBy?.name || ''),
      createDisplayFilter('organisation', organisation?.id || '', 'Organisation', organisation?.name || ''),
      createDisplayFilter(
        'collectionCountry',
        collectionCountry?.id || '',
        'Collection Country',
        collectionCountry?.name || ''
      ),
      createDisplayFilter(
        'deliveryCountry',
        deliveryCountry?.id || '',
        'Delivery Country',
        deliveryCountry?.name || ''
      ),
      createDisplayFilter('orderBy', formValues.orderBy, 'Order By', formValues.orderBy),
      createDisplayFilter('financeOwner', financeOwner?.id || '', 'Finance Owner', financeOwner?.name || '')
    ];
  }

  private keepFormValuesUpToDate(): void {
    this.filterTableHeaderService.filters.pipe(
      untilDestroyed(this)
    ).subscribe((values) => {
      this.patchFormValuesFromServiceValues(values);
    });
  }

  public listenToOrganisationChanges(): void {
    this._form.get('organisation')?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.financeOwners$ = of([]);
        if(value !== null) {
          this.financeOwners$ = this.organisationService.fetchUsersByOrganisationsAndPermissions([value], [PermissionKey.APPROVE_COSTING]);
        }
      });
  }

}
