<button
  class="inline-flex items-center justify-center font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-border bg-transparent shadow-sm rounded-md px-3 text-sm h-8 border-dashed">
  {{ filter.displayLabel }}
  <div data-orientation="vertical" role="none" class="shrink-0 bg-border w-[1px] mx-2 h-4"></div>
  <div
    class="inline-flex items-center border py-0.5 text-sm transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-foreground bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded px-1 font-normal lg:hidden">
    2
  </div>
  <div class="hidden space-x-1 lg:flex">
    <div
      data-testid="display-value"
      tabindex="0"
      class="inline-flex items-center border py-0.5 text-sm transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-foreground bg-accent rounded px-1 font-normal focus:text-red-500 focus:underline hover:text-red-500 hover:underline hover:cursor-pointer"
      (keydown.enter)="removeClicked.emit(filter)"
      (click)="removeClicked.emit(filter)"
    >
      {{ filter.displayValue }}
    </div>
  </div>
</button>
