import {Pipe, PipeTransform} from '@angular/core';
import {InteriorCargoType} from '@shared/models/interior-cargo-type.enum';
import {Package} from '@shared/models/package.model';
import {PackagingCalculationService} from '@shared/services/package/packaging-calculation.service';

@Pipe({
  name: 'countBoxes'
})
export class CountBoxesPipe implements PipeTransform {

  constructor(private calculationService: PackagingCalculationService) {}

  transform(value: Array<Package>, recursive = true): number {
    return this.calculationService.countPackagesByInteriorCargoType(value, InteriorCargoType.BOX, recursive);
  }

}
