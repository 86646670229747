<p-table
  [scrollable]="true"
  [value]="tableRows.controls"
  [tableStyle]="{width: '100%'}"
  [styleClass]="'p-datatable-sm'"
  *ngIf="packagingForm"
>
  <ng-template pTemplate="header">
    <tr>
      <th scope="col">Type</th>
      <th scope="col">Quantity</th>
      <th scope="col">Dimensions</th>
      <th scope="col">Weight Per Pack</th>
      <th *ngIf="tableRows.length > 1 && !readOnly" scope="col"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" [formGroup]="packagingForm">
    <ng-container formArrayName="packaging">
      <tr [formGroupName]="rowIndex">
        <td>
          <div class="pt-[18px]">
            <app-form-field>
              <p-dropdown
                appShowError
                appendTo="body"
                [options]="interiorCargoTypeOptions"
                optionLabel="label"
                [readonly]="readOnly"
                optionValue="value"
                formControlName="interiorCargoType"
                placeholder="Select type"
                [style]="{width: '150px'}"/>
            </app-form-field>
          </div>
        </td>
        <td>
          <div class="pt-[18px]">
            <app-form-field>
              <p-inputNumber
                appShowError
                inputId="integer-only"
                [readonly]="readOnly"
                formControlName="quantity"
                placeholder="Enter quantity"
                [inputStyle]="{width: '150px'}"
                [style]="{width: '150px'}"
              />
            </app-form-field>
          </div>
        </td>

        <td formGroupName="dimension">
          <div class="flex flex-row justify-between">
            <app-form-field class="flex flex-col">
              <label for="length" class="text-sm">Length</label>
              <p-inputNumber
                id="length"
                appShowError
                mode="decimal"
                [readonly]="readOnly"
                [suffix]="lengthSuffix"
                formControlName="length"
                placeholder="0.0"
                [minFractionDigits]="2"
                [inputStyle]="{width: '150px'}"
                [style]="{width: '150px'}"
              />
            </app-form-field>
            <app-form-field class="flex flex-col">
              <label for="width" class="text-sm">Width</label>
              <p-inputNumber
                id="width"
                appShowError
                mode="decimal"
                [readonly]="readOnly"
                [suffix]="lengthSuffix"
                formControlName="width"
                placeholder="0.0"
                [minFractionDigits]="2"
                [inputStyle]="{width: '150px'}"
                [style]="{width: '150px'}"/>
            </app-form-field>
            <app-form-field class="flex flex-col">
              <label for="height" class="text-sm">Height</label>
              <p-inputNumber
                id="height"
                appShowError
                mode="decimal"
                [readonly]="readOnly"
                [suffix]="lengthSuffix"
                formControlName="height"
                placeholder="0.0"
                [minFractionDigits]="2"
                [inputStyle]="{width: '150px'}"
                [style]="{width: '150px'}"/>
            </app-form-field>
          </div>
        </td>
        <td>
          <div class="pt-[18px] flex flex-row-reverse" formGroupName="weight">
            <app-form-field>
              <p-inputNumber
                appShowError
                mode="decimal"
                [suffix]="weightSuffix"
                [readonly]="readOnly"
                formControlName="value"
                placeholder="0.0"
                [minFractionDigits]="2"
                [inputStyle]="{width: '150px'}"
                [style]="{width: '150px'}"/>
            </app-form-field>
          </div>
        </td>
        <td *ngIf="tableRows.length > 1 && !readOnly">
          <p-button id="delete-row-button" styleClass="p-button-sm" [text]="true" (click)="onDeleteRow(rowIndex)">
            <i-lucide name="x" class="h-6"></i-lucide>
          </p-button>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

<div class="mt-2 flex items-center justify-end gap-x-6" *ngIf="!readOnly">
  <p-button id="add-row-button" label="Add new packaging row" [link]="true" (click)="addRow()">
    <i-lucide name="list-plus" class="h-6"></i-lucide>
  </p-button>
</div>

<div class="flex flex-wrap flex-grow justify-center gap-6 mt-8">

  <app-packaging-information
    heading="Total Pallet Count"
    [value]="(palletCount$ | async) || 0"
    colour="orange"
  >
    <lucide-angular name="boxes"></lucide-angular>
  </app-packaging-information>

  <app-packaging-information
    heading="Total Box Count"
    [value]="(boxCount$ | async) || 0"
    colour="green"
  >
    <lucide-angular name="box"></lucide-angular>
  </app-packaging-information>

  <!--'&#x00B3'-->
  <app-packaging-information
    heading="Volume"
    value="{{(volume$ | async) || 0 | number}} {{volumeSuffix}}³"
    colour="blue"
  >
    <lucide-angular name="scale-3d"></lucide-angular>
  </app-packaging-information>

  <app-packaging-information
    heading="Gross Weight"
    value="{{(grossWeight$ | async) || 0 | number}} {{weightSuffix}}"
    colour="teal"
  >
    <lucide-angular name="scale"></lucide-angular>
  </app-packaging-information>

  <app-packaging-information
    heading="Volumetric Weight"
    value="{{(volumetricWeight$ | async) || 0 | number}} {{weightSuffix}}"
    colour="teal"
  >
    <lucide-angular name="scale"></lucide-angular>
  </app-packaging-information>

  <app-packaging-information
    heading="Chargeable Weight"
    value="{{(chargeableWeight$ | async) || 0 | number}} {{weightSuffix}}"
    colour="teal"
  >
    <lucide-angular name="scale"></lucide-angular>
  </app-packaging-information>
</div>
