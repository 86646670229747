import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PrimeNgTableColumn} from '@shared/models/utility/prime-ng-helpers';
import {Pagination} from '@shared/models/utility/pagination.model';
import {Filter} from '@shared/models/utility/filter';

/*
 * Provides consolidated logic any values that are involved in table changes for a given page.
 * One place to modify and query from.
 * Can take this one step further and create interface + abstract implementation if we need different
 * Applications for different tables.
 * Takes an optional InjectionToken to use as the key for any localStorage persistence.
 */

export const LOCAL_STORAGE_KEY: InjectionToken<string> = new InjectionToken<string>('');
const localStorageKeyTypes = ['searchTerm', 'pagination', 'tableColumns',
  'filters'] as const;
type LocalStorageKeys = typeof localStorageKeyTypes[number];

@Injectable()
export class FilterTableHeaderService<T> {

  private searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private tableColumnsToShow$: BehaviorSubject<Array<PrimeNgTableColumn<T>>> = new BehaviorSubject<
    Array<PrimeNgTableColumn<T>>
  >([]);
  private pagination$: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({offset: 0, limit: 10});
  private filters$: BehaviorSubject<Array<Filter>> = new BehaviorSubject<Array<Filter>>([]);

  public allPossibleTableColumns: Array<PrimeNgTableColumn<T>> = [];

  constructor(
    @Optional() @Inject(LOCAL_STORAGE_KEY) private readonly localStorageKey: string
  ) {
    if(this.localStorageKey) this.loadFromLocalStorage();
  }

  public setAllPossibleTableColumns(columns: Array<PrimeNgTableColumn<T>>) {
    this.allPossibleTableColumns = columns;
    if(!localStorage.getItem(`${this.localStorageKey}_tableColumns`)) this.fallbackForColumnSelect();
  }

  public get searchTerm(): Observable<string> {
    return this.searchTerm$.asObservable();
  }

  public set searchTerm(value: string) {
    this.persistToLocalStorage('searchTerm', value);
    this.searchTerm$.next(value);
  }

  public get tableColumns(): Observable<Array<PrimeNgTableColumn<T>>> {
    return this.tableColumnsToShow$.asObservable();
  }

  public set tableColumns(value: Array<PrimeNgTableColumn<T>>) {
    let tempArray;
    if(this.allPossibleTableColumns.length > 0) {
      // Below keeps positional information compared to allPossibleTableColumns
      const valueSet = new Set(value.map((x) => x.field));
      tempArray = this.allPossibleTableColumns.filter((a) => valueSet.has(a.field));
    } else {
      tempArray = value;
    }
    this.persistToLocalStorage('tableColumns', tempArray);
    this.tableColumnsToShow$.next(tempArray);
  }

  public get filters(): Observable<Array<Filter>> {
    return this.filters$.asObservable();
  }

  public set filters(value: Array<Filter>) {
    const tempArray = value.filter((filter: Filter) => (filter.value !== null && filter.value) || undefined);
    this.persistToLocalStorage('filters', tempArray);
    this.filters$.next(tempArray);
  }

  public get pagination(): Observable<Pagination> {
    return this.pagination$.asObservable();
  }

  public set pagination(value: Pagination) {
    this.persistToLocalStorage('pagination', value);
    this.pagination$.next(value);
  }

  public reset(): void {
    this.searchTerm = '';
    this.tableColumns = [];
    this.filters = [];
    this.pagination = {offset: 0, limit: 5};
  }

  public clearSearchTerm(): void {
    this.searchTerm = '';
  }

  public removeFilter(filter: Filter): void {
    const filtersAfterRemoval = this.getFiltersAsValues().filter((x: Filter) => x !== filter);
    this.filters = filtersAfterRemoval;
  }

  // Only use this if really needed should use observable everywhere we can!
  public getFiltersAsValues(): Array<Filter> {
    return this.filters$.value;
  }

  public refresh(): void {
    this.searchTerm = this.searchTerm$.value;
  }

  private persistToLocalStorage(field: LocalStorageKeys, value: Pagination | Array<Filter> | string | Array<PrimeNgTableColumn<T>>): void {
    if(!this.localStorageKey) return;
    localStorage.setItem(`${this.localStorageKey}_${field}`, JSON.stringify(value));
  }

  private loadFromLocalStorage(): void {
    localStorageKeyTypes.forEach((key) => {
      const value = localStorage.getItem(`${this.localStorageKey}_${key}`);
      if(value) {
        if(key === 'pagination') this.loadPaginationFromStorage(value);
        else this[key] = JSON.parse(value);
      }
    });
  }

  private loadPaginationFromStorage(value: string): void {
    const limit = JSON.parse(value).limit;
    this.pagination = {offset: 0, limit: limit};
  }

  private fallbackForColumnSelect(): void {
    this.tableColumns = this.allPossibleTableColumns;
  }

}
