import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Country} from '@shared/models/country.model';
import {CountryService} from '@shared/services/country/country.service';
import {Observable, startWith} from 'rxjs';
import {Address} from 'src/app/shared/models/address.model';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html'
})
export class AddressFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input({required: true}) prefix!: string;
  @Input({required: true}) parentForm!: FormGroup;
  @Input({required: true}) parentFormGroupName!: string;
  @Input() address?: Address;
  @Input() readOnly = false;

  manualAddresses = false;
  addressForm: FormGroup;

  countryOptions$: Observable<Array<Country>>;

  constructor(
    private fb: FormBuilder,
    private countryService: CountryService
  ) {
    this.countryOptions$ = this.countryService.countries.pipe(startWith([]));
    this.addressForm = this.createFormGroup(this.address);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const addressChange = changes['address'];
    if(this.address && addressChange && addressChange.currentValue !== addressChange.previousValue) {
      this.addressForm.setValue({
        ...addressChange.currentValue
      });
    }
    this.manualAddresses = false;
  }

  ngOnInit(): void {
    if(this.parentForm) {
      this.parentForm.addControl(this.parentFormGroupName, this.addressForm);
      this.addressForm.setParent(this.parentForm);
    }
  }

  createFormGroup(address?: Address): FormGroup {
    return this.fb.group({
      name: [address?.name ?? null, [Validators.required, Validators.minLength(0), Validators.maxLength(255)]],
      addressLine1: [
        address?.addressLine1 ?? null,
        [Validators.required, Validators.minLength(0), Validators.maxLength(255)]
      ],
      addressLine2: [
        address?.addressLine2 ?? null,
        [Validators.minLength(0), Validators.maxLength(255)]
      ],
      city: [address?.city ?? null, [Validators.required, Validators.minLength(0), Validators.maxLength(255)]],
      zone: [address?.zone ?? null, [Validators.minLength(0), Validators.maxLength(255)]],
      postalCode: [
        address?.postalCode ?? null,
        [Validators.minLength(0), Validators.maxLength(64)]
      ],
      country: [address?.country ?? null, [Validators.required]]
    });
  }

  toggleManualAddresses(): void {
    this.manualAddresses = !this.manualAddresses;
  }

  ngOnDestroy(): void {
    if(this.parentForm) {
      this.parentForm.removeControl(this.parentFormGroupName);
    }
  }

}
