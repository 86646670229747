<form [formGroup]="monoIntervalForm">
  <label for="interval">{{label}}</label>
  <app-form-field>
    <div class="flex gap-3 p-fluid mt-2" id="interval">
      <p-dropdown
        [options]="intervalOptions"
        optionLabel="label"
        optionValue="value"
        formControlName="type"
        [readonly]="readOnly"
      />
      <p-inputNumber
        [styleClass]="'w-full'"
        [style]="{width: '100%'}"
        [readonly]="readOnly"
        formControlName="duration"
        placeholder = "Enter interval duration"
        appShowError
      />
    </div>
  </app-form-field>
</form>
