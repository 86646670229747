<p-table
  [columns]="tableColumns"
  [value]="data"
  [rows]="rowCount"
  [selectionMode]="selectionMode"
  [paginator]="pagination"
  [showCurrentPageReport]="true"
  [scrollable]="data.length > 0"
  [totalRecords]="totalRecords"
  currentPageReportTemplate=" {first} - {last} of {totalRecords}"
  [rowsPerPageOptions]="[10, 25, 50]"
  [resizableColumns]="true"
  [lazy]="true"
  (onLazyLoad)="lazyLoadChange($event)"
  [loading]="loading"
  [showLoader]="loading">
  <!-- Table Header -->
  <ng-template pTemplate="header" let-columns>
    <ng-container
      *ngIf="data.length > 0 && !loading; else headerNoDataFallback"
      [ngTemplateOutlet]="headerTemplate"
      [ngTemplateOutletContext]="{$implicit: columns}"></ng-container>
    <ng-template #headerNoDataFallback>
      <tr app-no-data-header></tr>
    </ng-template>
  </ng-template>
  <!-- Table Body -->
  <ng-template pTemplate="body" let-row let-columns="columns">
    <ng-container
      *ngIf="data.length > 0 && !loading; else rowNoDataFallback"
      [ngTemplateOutlet]="rowTemplate"
      [ngTemplateOutletContext]="{$implicit: row, columns: columns}"></ng-container>
    <ng-template #rowNoDataFallback>
      <tr app-no-data-row></tr>
    </ng-template>
  </ng-template>
  <!-- No Records Found -->
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td style="text-align: center">...</td>
    </tr>
  </ng-template>
</p-table>
