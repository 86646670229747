import {Component, ContentChild} from '@angular/core';
import {ShowErrorDirective} from '../../directives/show-error/show-error.directive';
import {ErrorService} from 'src/app/shared/services/error/error.service';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html'
})
export class FormFieldComponent {

  @ContentChild(ShowErrorDirective, {static: true})
    errorDirective!: ShowErrorDirective;

  constructor(private errorService: ErrorService) {}

  get errorMessage(): string | null {
    const errors = Object.entries(this.errorDirective?.ngControl?.control?.errors || {});

    if((!this.errorDirective?.ngControl?.dirty && !this.errorDirective?.ngControl?.touched) || !errors.length) {
      return null;
    }

    const passedControlName = this.errorDirective?.controlName;
    const formControlName = passedControlName ?? 'This field';
    return this.errorService.getErrorValidationMessage(formControlName, errors);
  }

}
