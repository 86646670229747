import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClickOutsideDirective} from '@shared/directives/click-outside/click-outside.directive';
import {ShowErrorDirective} from '@shared/directives/show-error/show-error.directive';
import {TableHeaderDirective} from '@shared/directives/table/table-header.directive';
import {TableRowDirective} from '@shared/directives/table/table-row.directive';

const DIRECTIVES = [ClickOutsideDirective, ShowErrorDirective, TableHeaderDirective, TableRowDirective];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES]
})
export class DirectivesModule { }
