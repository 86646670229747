import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public default = 'light';
  public themeChanged: BehaviorSubject<string> = new BehaviorSubject(this.theme);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.document.body.classList.add(localStorage.getItem('theme') ?? this.default);
  }

  public get theme(): string {
    return localStorage.getItem('theme') ?? this.default;
  }

  public set theme(value: string) {
    this.document.body.classList.remove(this.theme);
    localStorage.setItem('theme', value);
    this.document.body.classList.add(value);
    this.themeChanged.next(value);
  }

  public get isDark(): boolean {
    return this.theme === 'dark';
  }

}
