import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {getDimensionSuffix, getVolumeSuffix, getWeightSuffix} from '@core/utils/packaging-calculations';
import {Shipment} from '@shared/models/shipment.model';

@Component({
  selector: 'app-shipment-display-card',
  templateUrl: './shipment-display-card.component.html'
})
export class ShipmentDisplayCardComponent implements OnInit, OnChanges {

  @Input({required: true}) shipment!: Shipment;
  @Input() showStatus?: boolean = true;
  @Input() footer?: TemplateRef<any>;

  lengthSuffix: ' cm' | ' in' = ' cm';
  weightSuffix: ' kg' | ' lb' = ' kg';
  volumeSuffix: ' m' | ' in' = ' m';

  ngOnInit(): void {
    this.setSuffixValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['shipment'] && changes['shipment'].currentValue !== changes['shipment'].previousValue) {
      this.setSuffixValues();
    }
  }

  private setSuffixValues(): void {
    this.lengthSuffix = getDimensionSuffix(this.shipment.measurementSystem);
    this.weightSuffix = getWeightSuffix(this.shipment.measurementSystem);
    this.volumeSuffix = getVolumeSuffix(this.shipment.measurementSystem);
  }

}
