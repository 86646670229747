import {Component, Inject} from '@angular/core';
import {ThemeService} from '@core/services/theme.service';
import {filter} from "rxjs";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {DefaultUrlSerializer, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  title = 'Freight';
  private tokenCounter: number;
  private maxTokenCounter = 7;

  constructor(
    @Inject(Router) private router: Router,
    @Inject(KeycloakService) private keycloak: KeycloakService,
    public themeService: ThemeService,
  ) {
    this.tokenCounter = 0;
    this.listenForSessionTimedOut();
  }
  listenForSessionTimedOut(
  ): void {
    this.keycloak.keycloakEvents$.pipe(
      filter(event => event.type === KeycloakEventType.OnTokenExpired)
    ).subscribe(() => this.refreshToken())
  }

  refreshToken(): void {
    this.tokenCounter++;
      if (this.tokenCounter < this.maxTokenCounter) {
        this.keycloak.updateToken().catch(() => {
          this.router.navigate(['/session-timeout'], {queryParams: {lastVisited: this.router.url}})})
        return;
      }
      this.router.navigate(['/session-timeout'], {queryParams: {lastVisited: this.router.url}}).then(() => this.keycloak.logout());
  }

}

