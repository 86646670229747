import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ContactInformation} from '@shared/models/address.model';

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html'
})
export class ContactInfoFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input({required: true}) prefix!: string;
  @Input({required: true}) parentForm!: FormGroup;
  @Input({required: true}) parentFormGroupName!: string;
  @Input() contactInfo?: ContactInformation;
  @Input() readOnly = false;

  contactInfoForm!: FormGroup;

  constructor(private fb: FormBuilder) {
    this.contactInfoForm = this.createFormGroup(this.contactInfo);
  }

  ngOnInit(): void {
    if(this.parentForm) {
      this.parentForm.addControl(this.parentFormGroupName, this.contactInfoForm);
      this.contactInfoForm.setParent(this.parentForm);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const contactInfoChange = changes['contactInfo'];
    if(this.contactInfo && contactInfoChange && contactInfoChange.currentValue !== contactInfoChange.previousValue) {
      this.contactInfoForm.setValue({
        ...this.contactInfo
      });
    }
  }

  createFormGroup(contactInfo?: ContactInformation): FormGroup {
    return this.fb.group({
      name: [contactInfo?.name ?? null, [Validators.minLength(0), Validators.maxLength(64)]],
      email: [
        contactInfo?.email ?? null,
        [Validators.minLength(0), Validators.maxLength(255), Validators.email]
      ],
      phone: [
        contactInfo?.phone ?? null,
        [Validators.minLength(0), Validators.maxLength(32)]
      ]
    });
  }

  ngOnDestroy(): void {
    if(this.parentForm) {
      this.parentForm.removeControl(this.parentFormGroupName);
    }
  }

}
