import {Pipe, PipeTransform} from '@angular/core';
import {Address} from '../../models/address.model';

@Pipe({
  name: 'address',
  pure: true
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, ...args: unknown[]): string {
    const addressParts = [
      value.name,
      value.addressLine1,
      value.addressLine2,
      value.city,
      value.zone,
      value.country,
      value.postalCode
    ];

    return addressParts.filter((part) => !!part).join(', ');
  }

}
