import {Pipe, PipeTransform} from '@angular/core';
import {Weight} from '@shared/models/weight.model';

@Pipe({
  name: 'weight'
})
export class WeightPipe implements PipeTransform {

  transform(value: Weight, ...args: unknown[]): string {
    return `${value.value} ${value.unit.toLocaleLowerCase()}`;
  }

}
