<div class="border border-border rounded-lg w-full bg-background">
  <div class="flex flex-col h-full">
    <div class="border-border w-full border-b p-4 flex justify-between">
      <a class="text-lg font-semibold leading-none tracking-tight hover:text-primary hover:underline"
         [routerLink]="'/dashboard/shipment/'+shipment.shipmentNumber"> {{ shipment.shipmentNumber}} </a>
      <div class="border border-border rounded-md flex justify-center items-center px-2" *ngIf="showStatus">
        <app-shipment-status-tag [styled]="false" [status]="shipment.status"></app-shipment-status-tag>
      </div>
    </div>
    <div class="flex-1">
      <div class="grid grid-cols-2 h-full">
        <div class="col-span-1 border-r border-border p-4">
          <h4 class="font-semibold">Collection</h4>
          <p>{{ shipment.collectionAddress | address }}</p>
          <p>{{ shipment.expectedCollectionDate | dateFormat }}</p>

        </div>
        <div class="col-span-1 p-4">
          <h4 class="font-semibold">Delivery</h4>
          <p>{{ shipment.deliveryAddress | address}}</p>
          <p>{{ shipment.expectedDeliveryDate | dateFormat }}</p>
        </div>
      </div>
    </div>
    <div class="flex justify-center divide-x border-t border-border " *ngIf="shipment.packageStructure">
      <p class="p-4">{{ shipment.packageStructure.packaging | countPallets}} Pallets</p>
      <p class="p-4">{{ shipment.packageStructure.packaging | countBoxes}} Boxes</p>
      <p class="p-4">{{ shipment.packageStructure.packaging | volume: shipment.measurementSystem | number}} {{volumeSuffix}}³</p>
      <p class="p-4">{{ shipment.packageStructure.packaging | grossWeight | number}} {{weightSuffix}}</p>
    </div>
    <div *ngIf="footer" class="border-t border-border">
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  </div>
</div>
