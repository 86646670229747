<app-filter-table-header
  [dropdownOptions]="columnDropdownOptions"
  [initialDropdownValue]="initialColumnDropdownValues"
  [searchPlaceHolder]="'Search by shipment number'"
  (showFiltersClickedEvent)="showFilters()"
  (clearFiltersClickedEvent)="clearFilters()"
  [selectedFilters]="(filterTableHeaderService.filters | async) || []"
  (selectedColumnsChangedEvent)="filterTableHeaderService.tableColumns = $event"
  (searchTermChangedEvent)="filterTableHeaderService.searchTerm = $event"
></app-filter-table-header>
<div class="pb-4 overflow-hidden">
  <app-datatable
    [tableColumns]="(filterTableHeaderService.tableColumns | async) || []"
    [data]="shipmentList.data"
    [selectionMode]="'single'"
    [totalRecords]="shipmentList.total"
    (pageChangeEvent)="changePagination($event)"
    [loading]="(shipmentListLoading$ | async) || false"
    [rowCount]="(filterTableHeaderService.pagination | async)?.limit || 10"
  >
    <ng-template [appTableHeader]="columnDropdownOptions" let-columns>
      <tr app-shipment-selection-header [headers]="columns"></tr>
    </ng-template>
    <ng-template [appTableRow]="shipmentList.data" [rowColumns]="columnDropdownOptions" let-row let-columns="columns">
      <tr app-shipment-selection-row [data]="row" [headers]="columns" (click)="selectEvent.emit(row)"></tr>
    </ng-template>
  </app-datatable>
</div>

<app-shipment-filters-sidebar
  (applyFiltersEvent)="filterTableHeaderService.filters = $event"
>
</app-shipment-filters-sidebar>
