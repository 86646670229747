import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DropdownOption} from '@shared/models/utility/option.model';
import {MonoInterval, MonoIntervalType} from '@shared/models/interval.model';

@Component({
  selector: 'app-mono-interval',
  templateUrl: './mono-interval.component.html'
})
export class MonoIntervalComponent implements OnInit, OnChanges, OnDestroy {

  @Input({required: true}) label!: string;
  @Input() monoInterval ?: MonoInterval;

  @Input({required: true}) parentForm!: FormGroup;
  @Input({required: true}) parentFormGroupName!: string;
  @Input({required: true}) intervalOptions!: Array<DropdownOption<MonoIntervalType>>;
  @Input() required = true;
  @Input() readOnly = false;

  monoIntervalForm!: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const monoIntervalChange = changes['monoInterval'];
    if(this.monoIntervalForm && monoIntervalChange && monoIntervalChange.currentValue !== monoIntervalChange.previousValue) {
      this.monoIntervalForm.setValue({
        ...monoIntervalChange.currentValue
      });
    }
  }

  ngOnInit(): void {
    this.monoIntervalForm = this.createMonoIntervalGroup(this.monoInterval);
    if(this.parentForm) {
      this.parentForm.addControl(this.parentFormGroupName, this.monoIntervalForm);
      this.monoIntervalForm.setParent(this.parentForm);
    }
  }

  private createMonoIntervalGroup(monoInterval ?: MonoInterval): FormGroup {
    return this.fb.group({
      type: [monoInterval?.type ?? MonoIntervalType.DAY, this.required ? [Validators.required] : []],
      duration: [monoInterval?.duration ?? null, this.required ? [Validators.required] : []]
    });
  }

  ngOnDestroy(): void {
    if(this.parentForm) {
      this.parentForm.removeControl(this.parentFormGroupName);
    }
  }

}
