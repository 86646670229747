import {Pipe, PipeTransform} from '@angular/core';
import {Dimension} from '@shared/models/dimension.model';

@Pipe({
  name: 'dimension'
})
export class DimensionPipe implements PipeTransform {

  transform(value: Dimension, ...args: unknown[]): string {
    return `${value.length} ${value.unit.toLocaleLowerCase()} x ${value.width} ${value.unit.toLocaleLowerCase()} x ${value.height} ${value.unit.toLocaleLowerCase()}`;
  }

}
