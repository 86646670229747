import {Injectable} from '@angular/core';
import {Document, DocumentForm} from '@shared/models/document.model';
import {BehaviorSubject, Observable, shareReplay} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Page} from '@shared/models/utility/page.model';
import {HttpParamBuilder} from '@core/utils/http-param-builder';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  url = '/api/document';

  private _documents$: BehaviorSubject<Array<Document>> = new BehaviorSubject<Array<Document>>([]);
  public documents$: Observable<Array<Document>>;

  constructor(
    private httpClient: HttpClient
  ) {
    this.documents$ = this.fetchAllDocuments().pipe(shareReplay());
  }

  get documents(): Observable<Array<Document>> {
    return this.documents$;
  }

  public fetchDocuments(): void {
    this.fetchAllDocuments().subscribe((data) => this._documents$.next(data));
  }

  public downloadDocument(response: Blob, fileName: string) {
    const blob = new Blob([response] as BlobPart[], {type: 'application/octet-stream'});

    const link = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    link.href = objectUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(objectUrl);
    document.body.removeChild(link);
  }

  public fetchDocumentList(
    offset: number,
    size: number,
    search: string | null
  ): Observable<Page<Document>> {
    if(search === '') search = null;
    const params: HttpParams = HttpParamBuilder({offset, size, search});
    return this.httpClient.get<Page<Document>>(`${this.url}/list`, {params});
  }

  public fetchAllDocuments(): Observable<Array<Document>> {
    return this.httpClient.get<Array<Document>>(`${this.url}/list-all`);
  }

  public updateDocument(documentId: string, form: DocumentForm): Observable<void> {
    return this.httpClient.put<void>(`${this.url}/${documentId}`, form);
  }

  public getDocument(documentId: string): Observable<Document> {
    return this.httpClient.get<Document>(`${this.url}/${documentId}`);
  }

  public createDocument(form: DocumentForm): Observable<Document> {
    return this.httpClient.post<Document>(`${this.url}`, form);
  }

}
