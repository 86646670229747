import {
  AlertCircle, AlertTriangle,
  ArrowUpRight, Blocks, Box, Boxes, Check, CheckCircle,
  ChevronRight,
  ChevronsLeft,
  Container, Filter, FilterX, Forklift,
  Home, Info,
  ListChecks, ListPlus, MapPin,
  Menu,
  MoonStar, MoveRight, Pen, Scale, Scale3d,
  Settings,
  ShoppingBag, Snowflake, Trash, TrendingUp, User, X, XCircle, Plus, Download, Phone, Mail
} from 'lucide-angular';

// Only provide global icons here, if it's a one of please register on the feature module.
export const GLOBAL_ICONS = {
  Home,
  Menu,
  ChevronsLeft,
  MoonStar,
  ChevronRight,
  ShoppingBag,
  Settings,
  Container,
  ListChecks,
  TrendingUp,
  User,
  Filter,
  FilterX,
  ArrowUpRight,
  Check,
  Pen,
  Trash,
  Boxes,
  Box,
  X,
  ListPlus,
  XCircle,
  CheckCircle,
  Info,
  AlertCircle,
  Snowflake,
  Phone,
  Mail,
  Blocks,
  Forklift,
  AlertTriangle,
  MoveRight,
  Scale3d,
  Scale,
  MapPin,
  Plus,
  Download
};
