import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-packaging-information',
  templateUrl: './packaging-information.component.html'
})
export class PackagingInformationComponent {

  @Input({required: true}) heading!: string;
  @Input({required: true}) value!: string | number;

}
