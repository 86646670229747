import {Component} from '@angular/core';
import {CustomToastWrapperComponent} from '@shared/components/toasts/custom-toast-wrapper/custom-toast-wrapper.component';

@Component({
  selector: 'app-error-toast',
  templateUrl: './error-toast.component.html'
})
export class ErrorToastComponent extends CustomToastWrapperComponent {

}
