import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {ToastService} from '@shared/services/toast/toast.service';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    @Inject(ToastService) private toastService: ToastService,
    @Inject(Router) private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status === 401) this.router.navigate(['/auth/register']);
        if(error.status === 403) this.toastService.showErrorToast('Access to this resource is forbidden. Please contact support.');
        if(error.status === 500) this.toastService.showErrorToast();
        if(error.status === 408) this.toastService.showErrorToast('No response from the server');
        if(error.status === 404 || error.status === 400) this.toastService.showErrorToast(error.error.message ?? error.message);
        return throwError(() => error);
      })
    );
  }

}
