import {Injectable} from '@angular/core';
import {calculateVolume, calculateVolumetricWeight, calculateChargeableWeight} from '@core/utils/packaging-calculations';
import {InteriorCargoType} from '@shared/models/interior-cargo-type.enum';
import {MeasurementSystem} from '@shared/models/measurement-system.model';
import {Package} from '@shared/models/package.model';

@Injectable({
  providedIn: 'root'
})
export class PackagingCalculationService {

  public countPackagesByInteriorCargoType(packages: Array<Package>, type: InteriorCargoType, recursive: boolean): number {
    if(recursive) {
      packages = this.flatMapPackages(packages);
    }
    return packages
      .filter((pack: Package) => pack.interiorCargoType === type)
      .reduce((accumulator: number, pack: Package) => accumulator + pack.quantity, 0);
  }

  public calculateVolume(packages: Array<Package>, system: MeasurementSystem): number {
    return packages.reduce(
      (volume: number, pack: Package) => volume + (pack.quantity * calculateVolume(pack.dimension, system)), 0
    );
  }

  public calculateGrossWeight(packages: Array<Package>): number {
    return packages.reduce((weight: number, pack: Package) => weight + (pack.weight.value * pack.quantity), 0);
  }

  public calculateVolumetricWeight(packages: Array<Package>, system: MeasurementSystem): number {
    const volume = this.calculateVolume(packages, system);
    return calculateVolumetricWeight(volume, system);
  }

  public calculateChargeableWeight(packages: Array<Package>, system: MeasurementSystem): number {
    const volumetricWeight = this.calculateVolumetricWeight(packages, system);
    const grossWeight = this.calculateGrossWeight(packages);

    return calculateChargeableWeight(grossWeight, volumetricWeight);
  }

  private flatMapPackages(packaging: Array<Package>): Array<Package> {
    const flatten = (packages: Array<Package>) => {
      return packages.reduce<Array<Package>>((accumulator, next) => {
        if(next.interiorPackages && next.interiorPackages.length) {
          accumulator = accumulator.concat(flatten(next.interiorPackages));
        }
        accumulator.push(next);
        return accumulator;
      }, []);
    };
    return flatten(packaging);
  }

}
