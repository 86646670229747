import {Component} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-responsive-helper',
  templateUrl: './responsive-helper.component.html'
})
export class ResponsiveHelperComponent {

  public env: any = environment;

}
