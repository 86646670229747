import {Pipe, PipeTransform} from '@angular/core';
import {MeasurementSystem} from '@shared/models/measurement-system.model';
import {Package} from '@shared/models/package.model';
import {PackagingCalculationService} from '@shared/services/package/packaging-calculation.service';

@Pipe({
  name: 'volume'
})
export class VolumePipe implements PipeTransform {

  constructor(private calculationService: PackagingCalculationService) {}

  transform(value: Array<Package>, system: MeasurementSystem): number {
    return this.calculationService.calculateVolume(value, system);
  }

}
