import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent {

  @Input({required: true}) title = '';
  @Input() description?: string;
  @Input() buttonText?: string;
  @Input() showButton = false;

  @Output() buttonClickedEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public buttonClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.buttonClickedEvent.emit(event);
  }

}
