<form [formGroup]="userForm" *ngIf="userForm" (ngSubmit)="onSubmit()">
  <div class="flex flex-col space-y-6">
    <div class="grid grid-cols-2 gap-4">
      <div class="cols-span-1">
        <app-form-field>
          <label for="firstName">First Name</label>
          <div class="mt-2">
            <input
              id="firstName"
              appShowError
              type="text"
              [readOnly]="readOnly"
              formControlName="firstName"
              pInputText
              placeholder="Enter a name"
              [style]="{width: '100%'}" />
          </div>
        </app-form-field>
      </div>
      <div class="cols-span-1">
        <app-form-field>
          <label for="surname">Surname</label>
          <div class="mt-2">
            <input
              id="surname"
              appShowError
              [readOnly]="readOnly"
              type="text"
              formControlName="lastName"
              pInputText
              placeholder="Enter a surname"
              [style]="{width: '100%'}" />
          </div>
        </app-form-field>
      </div>
    </div>
    <app-form-field>
      <label for="email">Email</label>
      <div class="mt-1">
        <input
          id="email"
          appShowError
          type="text"
          [readonly]="readOnly || restricted"
          formControlName="email"
          pInputText
          placeholder="Enter an email"
          [style]="{width: '100%'}" />
      </div>
    </app-form-field>
    <app-form-field>
      <label for="organisations">Organisations</label>
      <div class="mt-1">
        <p-multiSelect
          id="organisations"
          [readonly]="readOnly"
          [options]="(organisationOptions$ | async) || []"
          formControlName="organisations"
          display="chip"
          [maxSelectedLabels]="100"
          optionLabel="label"
          optionValue="value"
          placeholder="Select organisations"
          [style]="{width: '100%'}">
        </p-multiSelect>
      </div>
    </app-form-field>
    <app-form-field>
      <label for="roles">Roles</label>
      <div class="mt-1">
        <p-multiSelect
          id="roles"
          [readonly]="readOnly"
          formControlName="roles"
          [options]="(roleOptions$ | async) || []"
          [maxSelectedLabels]="100"
          display="chip"
          placeholder="Select roles"
          [style]="{width: '100%'}">
        </p-multiSelect>
      </div>
    </app-form-field>
    <app-form-field *ngIf="!restricted">
      <label for="active">Active</label>
      <div class="mt-1">
        <p-inputSwitch
          id="active"
          appShowError
          [readonly]="readOnly"
          formControlName="active"/>
      </div>
    </app-form-field>
    <app-form-field *ngIf="!restricted">
      <label for="verified">Verified</label>
      <div class="mt-1">
        <p-inputSwitch
          id="verified"
          appShowError
          [readonly]="readOnly"
          formControlName="verified"/>
      </div>
    </app-form-field>
    <div class="mt-6 flex items-center justify-end gap-x-6" *ngIf="!readOnly">
      <p-button
        styleClass="p-button-raised p-button-danger"
        *ngIf="user"
        label="Cancel"
        (onClick)="onCancel()"
        [disabled]="apiRequestInProgress"/>
      <p-button
        label="Submit"
        type="submit"
        [loading]="apiRequestInProgress"
        [disabled]="apiRequestInProgress || !userForm.dirty || userForm.invalid"/>
    </div>
  </div>
</form>
