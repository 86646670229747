import {Component} from '@angular/core';
import {CustomToastWrapperComponent} from '@shared/components/toasts/custom-toast-wrapper/custom-toast-wrapper.component';

@Component({
  selector: 'app-warning-toast',
  templateUrl: './warning-toast.component.html'
})
export class WarningToastComponent extends CustomToastWrapperComponent {

}
