import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Filter} from '@shared/models/utility/filter';

@Component({
  selector: 'app-filter-chip',
  templateUrl: './filter-chip.component.html'
})
export class FilterChipComponent {

  @Input({required: true}) filter!: Filter;
  @Input() removable = true;
  @Output() removeClicked: EventEmitter<Filter> = new EventEmitter<Filter>();

}
