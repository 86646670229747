import {Organisation, User} from '@shared/models/user.model';
import {Country} from '@shared/models/country.model';

export interface ShipmentFilters {
  statuses: Array<string>;
  createdBy: User['id'];
  organisation: Organisation['id'];
  collectionCountry: Country['id'];
  deliveryCountry: Country['id'];
  orderBy: ShipmentListOrdering
  financeOwner: User['id']
}

export enum ShipmentListOrdering {
  CREATED_DATE = 'CREATED_DATE',
  UPDATED_DATE = 'LAST_MODIFIED'
}
