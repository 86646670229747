<p-sidebar [visible]="(isOpen | async) || false" position="right" [style]="{width: '400px'}" (onHide)="close()">
  <form [formGroup]="form">
    <div class="px-5">
      <div class="flex justify-between items-center mb-6">
        <h3 class="my-6 scroll-m-20 text-2xl font-semibold tracking-tight">Filter tasks</h3>
      </div>
      <div class="max-h-[calc(100vh-13rem)] overflow-y-scroll">
        <div class="mb-4">
          <div>
            <label for="type">Type</label>
          </div>
          <div>
            <p-dropdown
              id="type"
              formControlName="type"
              placeholder="Select type"
              [filter]="true"
              [style]="{width: '100%'}"
              appendTo="body"
              [options]="types"
            ></p-dropdown>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <label for="statuses">Status</label>
          </div>
          <div>
            <p-multiSelect
              id="statuses"
              formControlName="statuses"
              placeholder="Select status"
              [filter]="true"
              [style]="{width: '100%'}"
              appendTo="body"
              [options]="statuses"
            ></p-multiSelect>
          </div>
        </div>
        <div class="mb-4">
          <div>
            <label for="orderBy">Order By</label>
          </div>
          <div>
            <p-dropdown
              id="orderBy"
              formControlName="orderBy"
              placeholder="Select order by"
              [style]="{width: '100%'}"
              appendTo="body"
              [options]="orderByOptions"
            ></p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="relative flex px-4 mr-0 justify-end items-right left-0 right-0 bottom-0">
      <div class="my-6 flex space-x-4 mx-0" data-testid="form-buttons">
        <p-button label="Cancel" styleClass="p-button-sm p-button-secondary" (click)="cancel()"></p-button>
        <p-button label="Apply" styleClass="p-button-sm" (click)="apply()"></p-button>
      </div>
    </div>
  </form>
</p-sidebar>
